<template>
  <div class="onExceptPage" >

    <el-row v-if="getPre != null">
      <el-col :span="18">
        <div style=" height: auto" @click="openViewer">
          <canvas id="wrongCanvas" width="1000" height="1000"></canvas>
        </div>
        <div style="margin-top: 20px; height: auto" @click="openViewer">
          <canvas id="myCanvas" width="1000" height="1000"></canvas>
<!--          <img style="width: 100%; height: 100%" class="zoomImg" :ref="'imgContainer1'" @load="loadImage"-->
<!--               :src="getPre.cutOssFullName" @mousewheel="handleZoomImg($event, 'imgContainer1')" />-->
        </div>
      </el-col>
      <el-col :span="6">
        <div class="right-part" >
          <el-row  >
            <el-col :span="12">
              <el-link type="primary" @click="setShowBase">{{isShowBase?'隐藏':'显示'}}基本信息及操作</el-link>
            </el-col>
          </el-row>
          <el-row class="base-info" v-show="isShowBase">
            <el-col :span="24" style="text-align: center">
              基本信息
            </el-col>
            <div>
              <el-col :span="24" style="white-space:nowrap;">
                批次号：{{scanPre.batch_id}}
              </el-col>
              <el-col :span="24">
                序号：{{scanPre.seq}}
              </el-col>
              <el-col :span="24">
                页码：{{scanPre.page_no}}
              </el-col>
              <el-col :span="24">
                状态：<span :class="scanPre.status=='ZC'?'': 'status-error'">{{formatStatus(scanPre.status)}}</span>
              </el-col>
              <el-col :span="24">
                学号：{{scanPre.student_no}}
              </el-col>
              <el-col :span="24">
                姓名：{{scanPre.student_name}}
              </el-col>
              <el-col :span="24" v-if="scanPre.paper_ab!=null">
                AB卷：{{scanPre.paper_ab}}卷
              </el-col>
              <el-col :span="24" style="color: #F6F7FC;white-space:nowrap;">
                id：{{scanPre.id}}
              </el-col>
              <el-col :span="24" style="color: #F6F7FC;white-space:nowrap;">
                same id：{{scanPre.same_exam_id}}
              </el-col>
            </div>
          </el-row>
          <el-row v-show="isShowBase" style="text-align: center" class="base-info">
            <el-col :span="24">
              基本操作
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='showChangeExamDialog()'>更换考试</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='changeStudentNo()'>更改学号</el-button>
            </el-col>
<!--            <el-col :span="24" style="margin-top: 15px;" v-if="pageLen > 1">-->
<!--              <el-button type="primary" size="mini" @click='changePageNo'>扫反纠正</el-button>-->
<!--            </el-col>-->
          </el-row>
          <el-row v-show="isShowBase" style="text-align: center" class="base-info">
            <el-col :span="24">
              特殊操作
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='resetSB()'>再校对</el-button>
            </el-col>

          </el-row>
          <el-row v-show="isShowBase" style="margin-top: 20px; text-align: center" class="base-info">
            <el-col :span="24" style="color: red; ">
              危险操作
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="danger" size="mini" @click='doDeleteScanFile()'>删除</el-button>
            </el-col>
          </el-row>

          <el-row  class="base-info" :style="myStyle">
            <el-col :span="24">
              填涂处理
            </el-col>
            <el-col :span="24" v-for="(answers, ans_idx) in answerList">
              <el-row v-for="(item, index) in answers.list" style="margin-top: 22px;">
                <el-col :span="1" style="text-align: right; margin-top: 5px;">
                  {{item.no}}
                </el-col>
                <el-col :span="23" style="text-align: left">
                  <el-radio-group v-model="item.answer" size="small" v-if="item.type =='SingleChoice'">
                    <el-radio-button :label="c" v-for=" c in item.chooseList" style="margin-left: 4px;"></el-radio-button>
                  </el-radio-group>
                  <el-checkbox-group v-model="item.answer" size="small" v-if="item.type =='MultiChoice'">
                    <el-checkbox-button :label="c" v-for=" c in item.chooseList" :key="c" style="margin-left: 4px;"></el-checkbox-button>
                  </el-checkbox-group>
                  <el-radio-group v-model="item.answer" size="small" v-if="item.type =='Judge'">
                    <el-radio-button :label="c" v-for=" c in item.chooseList" style="margin-left: 8px;"></el-radio-button>
                  </el-radio-group>
                </el-col>
              </el-row>
              <div class="fenge"></div>
            </el-col>
            <el-col :span="24" style="margin-top: 20px; text-align: center">
              <el-button type="primary"  :loading="loading" @click='saveRightAnswer()'>确认填涂</el-button>
            </el-col>
          </el-row>


        </div>
      </el-col>
    </el-row>

    <studentBox title="选择学生" :scanFileId="scanPre.id" ref="studentBox" @ok="selectStudent" :exceptType="scanPre.status" :updateStatus="'C1'"
                :examId="scanPre.exam_id" :searchText="null" :sameExamId="scanPre.same_exam_id" :top="200" :left="300" :right="0"></studentBox>

    <changeExamDialog ref="changeExamDialog" :scanPre="scanPre" @ok="selectChangeExam"></changeExamDialog>
    <el-image-viewer v-if="showViewer && examImageList" :on-close="closeViewer" :url-list="examImageList" />
  </div>

</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import XEUtils from 'xe-utils'
  import * as simpleApi from "request/simple";
  import * as mUtils from '@/utils/mUtils'
  import studentBox from "./studentBox";
  import changeExamDialog from "./changeExamDialog"
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

  export default {
    name: 'onExceptPage',
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    props: {
      info: {
        type: Object
      }
    },
    components:{
      studentBox, changeExamDialog, ElImageViewer
    },
    watch: {
      info: {
        deep: true, immediate:true,
        handler(n, o){
          console.log(n)
          if(n){
            this.scanPre = n;
            this.loadExamPages();
          }
        }
      },
    },
    data() {
      return {
        scanPre: null, scanList: [], searchText: '', showViewer: false, isShowBase: false,
        url: '', id: null, scanFileId: null, examId: null, myStyle: null, pageLen: 1,
        theDialogVisible: true, loading:false, tableData: [], answerList: [], examImageList: [],
        getPre: null, imgInfo: null, pointList:[], cardInfo: null, selPoint: null,
        searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null},
      }
    },
    mounted() {
      let myHeight = document.documentElement.clientHeight ;
      this.myStyle = {height: (myHeight-200) + 'px', overflow: "auto", textAlign: "center"};

    },
    destroyed() {

    },
    methods: {

      formatStatus(status){
        return mUtils.examStatus(status);
      },
      setShowBase(){
        this.isShowBase = !this.isShowBase;
      },
      // 滚轮放大缩小图片 方法
      handleZoomImg(evt, imgName) {
        // console.log('handleZoomImg', imgName)
        evt = evt || window.event
        if (!evt) evt = window.event
        if (evt.stopPropagation) { // 这是取消冒泡,阻止浏览器滚动事件
          evt.stopPropagation()
        } else {
          evt.cancelBubble = true
        };
        if (evt.preventDefault) { // 这是取消默认行为，要弄清楚取消默认行为和冒泡不是一回事
          evt.preventDefault()
        } else {
          evt.returnValue = false
        };
        let delta = 0
        if (evt.wheelDelta) {
          delta = evt.wheelDelta / 120
          if (window.opera) delta = -delta
        } else if (evt.detail) {
          delta = -evt.detail / 3
        }
        let myImgContainer =  this.$refs[imgName]
        // console.log("imgContainer", myImgContainer)
        if(myImgContainer ){
          let imgContainer = myImgContainer;

          let width = imgContainer.offsetWidth
          let height = imgContainer.offsetHeight
          if (delta > 0) {
            imgContainer.style.width = `${width * 1.1}px`
            imgContainer.style.height = `${height * 1.1}px`
          } else if (delta < 0) {
            imgContainer.style.width = `${width * 0.9}px`
            imgContainer.style.height = `${height * 0.9}px`
          }
        }
      },
      loadExamPages(){
        let that = this;
        console.log("loadExamPages")
        let search = { licenceId: this.userInfo.licenceId, id: this.scanPre.id};
        let param = { controllerName: 'scan/scanPre', methodName: '/getById',  param: search};
        return simpleApi.list(param).then(({result: {code, data}}) =>  {
          console.log("loadExamPages", data)
          if (data) {
            this.getPre = data;
            this.getPre.cutOssFullName = mUtils.urlAddTimestamp(this.getPre.cutOssFullName);
            this.loadCanvas();
          }
        }).catch((error) => {
          console.log("error", error)
        });
      },

      loadCanvas(){
        let that = this;
        this.$nextTick(() => {
          var canvas = document.getElementById("myCanvas");
          var wrongCanvas = document.getElementById("wrongCanvas");
          let tt = document.documentElement.clientWidth / 2;
          if(canvas.getContext){
            let ctx = canvas.getContext('2d');
            let img = new Image();
            img.src = this.getPre.cutOssFullName;
            img.onload = function () {
              let scale = 1;
              if(this.width > tt||this.height > tt){
                if(this.width > this.height) scale = tt/this.width;
                if(this.height > this.width) scale = tt/this.height;
                ctx.width = this.width * scale;
                ctx.height = this.height * scale;
                canvas.width = ctx.width;
                canvas.height = ctx.height;
                let wrongCtx = wrongCanvas.getContext('2d');
                wrongCtx.width = ctx.width;
                wrongCanvas.width = ctx.width;
                ctx.drawImage(this, 0, 0, ctx.width, ctx.height);
                that.cardInfoAndImage(ctx, wrongCanvas, wrongCtx, this);
              }
            }
          }
        });

      },
      cardInfoAndImage(ctx, wrongCanvas, wrongCtx, img){
        try{
          this.answerList = [];
          if(this.getPre.cardInfo){
            let cardInfo = JSON.parse(this.getPre.cardInfo);
            this.pageLen = cardInfo.length;
            // console.log("card", cardInfo);
            let width = ctx.width;
            let height = ctx.height;
            if(cardInfo && cardInfo.length > 0){
              let wrongRect = [];
              for (let i=0; i<cardInfo[this.getPre.pageNo-1].sections.length; i++){
                let sect = cardInfo[this.getPre.pageNo-1].sections[i];
                for (let j=0; j<sect.list.length; j++){
                  if(sect.list[j].type == "Object"){
                    let pos = sect.list[j].position;
                    let branchs = sect.list[j].branch;
                    // console.log('pos', pos, branchs)
                    for (let k=0; k<branchs.length; k++){
                      let branch = branchs[k];
                      if(branch.is_error){
                        ctx.strokeStyle = 'red';
                        ctx.lineWidth = 1;
                        let posWidth = pos.width_scale*width;
                        let posHeight = pos.height_scale*height;
                        let x = pos.left_begin*width + posWidth * branch.left_begin;
                        let y = pos.top_begin*height + posHeight * branch.top_begin;
                        let w = posWidth*branch.width_ratio;
                        let h = posHeight*branch.height_ratio;
                        if("stu_answer" in branch){
                          ctx.fillStyle = 'red'
                          let stu_anw = branch.stu_answer;
                          let numList = branch.numList;
                          let ixList = branch.ixList;
                          let a_h = h / stu_anw.length;
                          let group_anw = [];
                          for (let m=0; m<stu_anw.length; m++){
                            let a_x = x+5;
                            let a_y = y+10 + m * a_h;
                            ctx.fillText(numList[m] + "."+stu_anw[m], a_x, a_y)
                            let stu_a = stu_anw[m];
                            if(branch.subAreaTopicType != 'MultiChoice'){
                              if(stu_a == "") stu_a = "未选";
                              if(stu_a && stu_a.length > 2) stu_a = "多选";
                            }
                            // console.log("answer", branch, stu_a)
                            let p = {type: branch.subAreaTopicType, no: numList[m], ix: ixList[m], answer: stu_a, chooseList: [...branch.chooses, "未选", "多选"]};
                            group_anw.push(p);
                          }
                          this.answerList.push({id: branch.id, list: group_anw});
                        }
                        ctx.strokeRect(x, y, w, h);

                        let wposWidth = pos.width_scale*img.width;
                        let wposHeight = pos.height_scale*img.height;
                        let wx = pos.left_begin*img.width + wposWidth * branch.left_begin;
                        let wy = pos.top_begin*img.height + wposHeight * branch.top_begin;
                        let ww = wposWidth*branch.width_ratio;
                        let wh = wposHeight*branch.height_ratio;

                        wrongRect.push({x: wx, y: wy, w: ww, h: wh})
                        // wrongCtx.strokeRect(wx, wy, ww, wh);
                        // console.log('draw', branch, pos);
                      }
                    }
                    this.drawChoose(ctx, height, width, pos, branchs);
                  }

                }
              }
              if(wrongRect.length > 0){
                let wrongLen = wrongRect.length;
                let colWidth = wrongCtx.width / 3;
                let rowHeight = 250;
                if(wrongLen > 3){
                  wrongCtx.height =  rowHeight * (parseInt(wrongLen / 3) + 1);
                  wrongCanvas.height = wrongCtx.height;
                  console.log( "wrongLen", wrongLen, wrongCtx.height)
                }else{
                  wrongCtx.height = rowHeight;
                  wrongCanvas.height = rowHeight;
                }
                let dy = 0;
                for (let k=0; k<wrongLen; k++){
                  let rect = wrongRect[k];
                  // let n = k+1;
                  let dx = 0;
                  if(k%3==0) dx = 0; else if(k%3==1) dx = colWidth; else dx = colWidth*2;
                  if(k > 0){
                    dy = dy + (k%3==0?rowHeight:0);
                  }
                  console.log("drawImage", dx, dy, colWidth, rowHeight)
                  wrongCtx.drawImage(img, rect.x, rect.y, rect.w, rect.h, dx+5, dy+5, colWidth-5, rowHeight-5);
                }
              }
            }
            this.cardInfo = cardInfo;
          }
        }catch (e) {
          console.log("error", e)
        }
      },
      drawChoose(ctx, height, width, pos, branchs){
        let posLeft = pos.left_begin*width;
        let posTop = pos.top_begin*height;
        let posWidth = pos.width_scale*width;
        let posHeight = pos.height_scale*height;
        let row_x = 0, row_y = 0, x = 0, y = 0;
        for(let i=0; i< branchs.length; i++){
          let branch = branchs[i] ;
          let b_t = posHeight * branch.top_begin;
          let b_l = posWidth * branch.left_begin;
          let b_h = posHeight * branch.height_ratio;
          let b_w = posWidth * branch.width_ratio;
          let f_x = b_w * (branch.firstOption.left / branch.position.width);
          let f_y = b_h * (branch.firstOption.top / branch.position.height);
          if(row_y != posHeight*(branch.position.top / pos.height)){
            row_y = posHeight*(branch.position.top / pos.height);
            row_x = 0;
          }
          let len_num = branch.numList.length;
          let row_height = 0, ge_height = 0;
          if(len_num == 1){
            row_height = b_h - f_y*2;
          }else{
            let chu_len = len_num * 3 + (len_num-1)*2;
            let chu_v = (b_h - f_y*2) / chu_len;
            row_height = chu_v * 3;
            ge_height = chu_v * 2;
          }
          let col_width = (b_w-f_x) / branch.chooses.length;
          let base_l = posLeft + f_x;
          for(let j=0; j<len_num; j++){
            y = f_y + j * row_height + j*ge_height + row_y;
            for(let k=0; k<branch.chooses.length; k++){
              x = row_x +base_l + k * col_width;
              x = k==0?(x):(x+col_width * (0.05+k*0.05));
              // console.log("rect",  j, k, x, posTop+y, col_width*0.7, row_height)
              let cell_x = x-col_width*0.1;
              let cell_y = posTop+y - row_height * 0.1;
              let cell_w = col_width*0.7
              let cell_h = row_height * 1.2
              ctx.strokeRect(cell_x, cell_y, cell_w, cell_h);
            }
          }
          row_x = row_x + b_w;
        }

      },
      showChangeExamDialog(){
        this.$refs.changeExamDialog.showDialog();
      },
      selectChangeExam(){
        this.$refs.changeExamDialog.hideDialog();
        this.$message({ type: 'success', message: '更新成功!'});
        this.$emit('changeExam');
      },
      changeStudentNo(){
        this.$refs.studentBox.showDialog(this.getPre.studentNo);
      },
      selectStudent(sameExamId){
        this.$refs.studentBox.hideDialog();
        this.$message({ type: 'success', message: '更新成功!'});
        this.$emit('next', this.getPre.status, sameExamId);
      },
      saveRightAnswer(){
        for (let i=0; i<this.answerList.length;i++){
          let anw_list = this.answerList[i];
          let anwId = anw_list.id;
          let anwList = anw_list.list;
          console.log("anwList", anwId, anwList);
          if(this.cardInfo && this.cardInfo.length > 0){
            for (let ii=0; ii<this.cardInfo[this.getPre.pageNo-1].sections.length; ii++){
              let sect = this.cardInfo[this.getPre.pageNo-1].sections[ii];
              for (let jj=0; jj<sect.list.length; jj++){
                if(sect.list[jj].type == "Object"){
                  let branchs = sect.list[jj].branch;
                  for (let kk=0; kk<branchs.length; kk++){
                    let branch = branchs[kk];
                    if(branch.id == anwId){
                      console.log("branch", branch);
                      if("stu_answer" in branch){
                        let stu_anw = branch.stu_answer;
                        let ixList = branch.ixList;
                        for (let m=0; m<ixList.length; m++){
                          let myAnw = anwList[m];
                          if(ixList[m] == myAnw.ix){
                            if(myAnw.answer == "未选" || myAnw.answer == "多选"){
                              stu_anw[m] = "";
                            }else{
                              stu_anw[m] = myAnw.answer;
                            }

                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }
        this.loading = true;
        let param = { controllerName: 'scan/scanPre', methodName: '/updateCardInfoByStatus',
          param: { id: this.scanPre.id, newStatus: 'ZC', cardInfo: JSON.stringify(this.cardInfo) }};
        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
          if (code == 0) {
            this.$message({ type: 'success', message: '更新填涂成功!'});
            this.$emit('ok');
          }else{
            this.$message({ type: 'success', message: '更新填涂失败!'});
          }
          this.loading = false;
        });
      },
      openViewer(){

        this.examImageList = [];
        if(this.getPre.ossFullName != null){
          this.examImageList.push(this.getPre.ossFullName);
        }
        this.showViewer = true;
      },
      closeViewer(){
        this.showViewer = false;
      },
      resetSB(){
        let that = this;
        let status = this.getPre.status;
        this.$confirm('将此试卷重新校对?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {
          let param = { controllerName: 'scan/scanPre', methodName: '/updateById', param: { id: this.scanPre.id, status: "01"}};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '操作成功!'});
              this.$emit('ok', status);
            }
          });
        }).catch(() => {

        });
      },
      changePageNo(){
        let that = this;
        let status = this.getPre.status;
        this.$confirm('本操作应用于试卷倒反扫描，将改变页码顺序，是否确定操作?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {

          let param = { controllerName: 'scan/scanPre', methodName: '/changePageNo', param: { sameExamId: this.getPre.sameExamId, status: '01' }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '变更成功!'});
              this.$emit('ok', status);
            }
          });
        }).catch(() => {

        });
      },
      doDeleteScanFile(){
        let that = this;
        let status = this.getPre.status;
        this.$confirm('将删除此试卷?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {

          let param = { controllerName: 'scan/scanPre', methodName: '/deleteBySameExamId', param: { sameExamId: this.getPre.sameExamId }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '删除成功!'});
              this.$emit('ok', status);
            }
          });
        }).catch(() => {

        });
      },
    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

  .onExceptPage {

    .status-error{
      color: red;
    }
    .el-form-item__label {
      font-size: 16px;
      color: black;
    }
    .right-part {
      z-index: 999;
      padding: 10px;
      border: 1px solid rgba(203, 202, 202, 0.71);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .data-row{
        margin-bottom: 10px;
      }
      .student-no-lost {
        text-align: left;
      }
      .fenge {
        margin-top: 15px;
        border-bottom: 1px solid rgba(203, 202, 202, 0.71);
      }
      .base-info {
        text-align: left;
        margin-top: 10px;
        padding: 5px;
        border: 1px solid rgba(203, 202, 202, 0.71);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

  }




</style>
