<template>
    <div class="scanPre" v-loading="loading">
        <el-row style="margin-top: 10px; margin-bottom: 10px;">
            <el-col :span="8">
                考试：
                <el-select v-model="examId" filterable placeholder="请选择" @change="examChange" style="min-width: 400px;">
                    <el-option v-for="item in examList" :key="item.id" :label="item.name+'('+ item.id+ ')'" :value="item.id"></el-option>
                </el-select>
            </el-col>
            <el-col :span="12" v-if="examId != null">
                <div @click="loadScanCount">
                    <span style="color: blue">未预处理：{{examCount.notPre}}，待校对：{{examCount.preing}}，定位异常：{{examCount.exceptY}}， 识别中:{{examCount.ocring}}，异常：{{examCount.except}}，已识别：{{examCount.ocred}}，
                        入库：{{examCount.rk}}，
                        共{{examCount.notPre+examCount.preing+examCount.ocring+examCount.except+examCount.ocred}}张</span>
                </div>
            </el-col>
            <el-col :span="4" v-if="examId != null">
                <el-button type="primary" size ="mini" icon="search" @click='examChange'>加载列表</el-button>
            </el-col>

        </el-row>
        <el-tabs v-model="tagName" v-if="examId!=null" type="border-card" @tab-click="tabClick" style="padding: 10px;">
            <el-tab-pane :label="'定位异常(' + (exceptList?(''+exceptList.length):'0') + ')'" name="dwyc">
                <el-row>
                    <el-col :span="4" style="padding-left: 10px; padding-right: 10px;">
                        <div class="left-part" :style="leftStyle">
                            <el-row class="data-row" v-if="exceptList && exceptList.length > 0" v-for="(item, index) in exceptList">
                                <el-col :span="24" class="student-no-lost">
                                    <el-link type="primary" @click="openExamExcept(item)">{{getExceptName(index+1, item)}}</el-link>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :span="20" >
<!--                        <examImageEditor v-if="selExamExcept != null" :info="selExamExcept"  @ok="nextExamExcept" @changeExam="nextStudentNoLost"></examImageEditor>-->
                      <posExceptPage v-if="selExamExcept != null" :info="selExamExcept"  @ok="nextExamExcept" @changeExam="nextStudentNoLost"></posExceptPage>
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane :label="'待校对(' + examCount.preing + ')'" name="dsb">
                <el-row>
                    <el-col :span="4" style="padding-left: 10px; padding-right: 10px;">
                        <div class="left-part" :style="leftStyle">
                            <el-row class="data-row" v-if="waitList && waitList.length > 0" v-for="(item, index) in waitList">
                                <el-col :span="24"  class="student-no-lost">
                                    <el-link type="primary" @click="openWaiting(item)">{{index+1}}、卷{{"-"+item.seq}}{{item.page_no > 1?"-第二页":""}}</el-link>
                                </el-col>

                            </el-row>
                        </div>
                    </el-col>
                    <el-col :span="20" >
                        <waitPage ref="waitPage" v-if="selWait!=null" :info="selWait" @ok="examChange"></waitPage>
                    </el-col>

                </el-row>

            </el-tab-pane>

            <el-tab-pane :label="'异常(' + examCount.except + ')'" name="yc">
                <el-row >

                    <el-col :span="4">
                        <div class="right-part">

                            <el-row style="margin-top: 20px;">
                                <el-col :span="24">
                                    <el-collapse v-model="activeName" accordion @change="collapseChange" >
                                        <el-collapse-item name="1">
                                            <template slot="title">
                                                <span class="collapse-title">学号异常</span>
                                                <span v-if="loseNoList && loseNoList.length > 0" class="collapse-title" style="color: red;" >({{loseNoList.length}})</span>
                                            </template>
                                            <el-row class="data-row" v-if="loseNoList && loseNoList.length > 0" v-for="(item, index) in loseNoList">
                                                <el-col :span="19" class="student-no-lost">
                                                    <el-link type="primary" @click="openStudentNoLost(item)">{{index+1}}、XXXXXXXX</el-link>
                                                </el-col>
                                                <el-col :span="5" >
                                                    <el-link type="primary"  size="mini" @click="onBottom(item)" >置底</el-link>
                                                </el-col>

                                            </el-row>

                                        </el-collapse-item>
                                        <el-collapse-item name="2">
                                            <template slot="title">
                                                <span class="collapse-title">学号丢失</span>
                                                <span v-if="loseNoY1List && loseNoY1List.length > 0" class="collapse-title" style="color: red;" >({{loseNoY1List.length}})</span>
                                            </template>
                                            <el-row class="data-row" v-if="loseNoY1List && loseNoY1List.length > 0" v-for="(item, index) in loseNoY1List">
                                                <el-col  :span="19" class="student-no-lost">
                                                    <el-link type="primary" @click="openStudentNoLost(item)">{{getExamName(index+1, item)}}</el-link>
                                                </el-col>
                                                <el-col :span="5" >
                                                    <el-link type="primary"  size="mini" @click="onBottom(item)" >置底</el-link>
                                                </el-col>

                                            </el-row>
                                        </el-collapse-item>

                                        <el-collapse-item name="5">
                                            <template slot="title">
                                                <span class="collapse-title">学号重复</span>
                                                <span v-if="duplicList && duplicList.length > 0" class="collapse-title" style="color: red;" >({{duplicList.length}})</span>
                                            </template>
                                            <el-row class="data-row" v-if="duplicList && duplicList.length > 0" v-for="(item, index) in duplicList">
                                                <el-col :span="19" class="student-no-lost">
                                                    <el-link type="primary" @click="openStudentNoLost(item)">{{(index+1) + "." + item.student_name + "(" + item.student_no + ")"}}</el-link>
                                                </el-col>
                                                <el-col :span="5" >
                                                    <el-link type="primary"  size="mini" @click="onBottom(item)" >置底</el-link>
                                                </el-col>
                                            </el-row>
                                        </el-collapse-item>
                                        <el-collapse-item name="9" v-if="abList && abList.length > 0">
                                            <template slot="title">
                                                <span class="collapse-title">AB卷异常</span>
                                                <span  class="collapse-title" style="color: red;" >({{abList.length}})</span>
                                            </template>
                                            <el-row class="data-row" v-if="abList && abList.length > 0" v-for="(item, index) in abList">
                                                <el-col :span="19"  class="student-no-lost">
                                                    <el-link type="primary" @click="openStudentNoLost(item)">{{getExceptName(index+1, item)}}</el-link>
                                                </el-col>
                                                <el-col :span="5" >
                                                    <el-link type="primary"  size="mini" @click="onBottom(item)" >置底</el-link>
                                                </el-col>
                                            </el-row>
                                        </el-collapse-item>
                                        <el-collapse-item name="7" v-if="page1NoFoundList && page1NoFoundList.length > 0">
                                            <template slot="title">
                                                <span class="collapse-title">第一页异常</span>
                                                <span class="collapse-title" style="color: red;" >({{page1NoFoundList.length}})</span>
                                            </template>
                                            <el-row class="data-row" v-for="(item, index) in page1NoFoundList">
                                                <el-col :span="19"  class="student-no-lost">
                                                    <el-link type="primary" @click="openStudentNoLost(item)">{{getExceptName(index+1, item)}}</el-link>
                                                </el-col>
                                                <el-col :span="5" >
                                                    <el-link type="primary"  size="mini" @click="onBottom(item)" >置底</el-link>
                                                </el-col>
                                            </el-row>
                                        </el-collapse-item>
                                        <el-collapse-item name="6">
                                            <template slot="title">
                                                <span class="collapse-title">填涂异常</span>
                                                <span v-if="wrongOcrList && wrongOcrList.length > 0" class="collapse-title" style="color: red;" >({{wrongOcrList.length}})</span>
                                            </template>

                                            <el-row class="data-row" v-if="wrongOcrList && wrongOcrList.length > 0" v-for="(item, index) in wrongOcrList">
                                                <el-col :span="19"  class="student-no-lost">
                                                    <el-link type="primary" @click="openAnswerNotClear(item)">
                                                        {{item.student_name?(item.student_name + '(' + item.student_no + ")"):("填涂异常"+index)}}
                                                    </el-link>
                                                </el-col>
                                                <el-col :span="5" >
                                                    <el-link type="primary"  size="mini" @click="onBottom(item)" >置底</el-link>
                                                </el-col>

                                            </el-row>

                                        </el-collapse-item>
                                    </el-collapse>
                                </el-col>
                            </el-row>

                        </div>

                    </el-col>
                    <el-col :span="20" >
                        <exceptPage v-if="selExcept!= null" ref="exceptPage" :info="selExcept" @ok="examChange" @next="nextStudentNoLost" @changeExam="nextStudentNoLost"></exceptPage>
                        <ocrExceptPage v-if="selOcrExcept!= null" ref="selOcrExcept" :info="selOcrExcept" @ok="nextAnswerExcept" @changeExam="nextAnswerExcept"></ocrExceptPage>

                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane :label="'正常(' + examCount.ocred + ')'" name="zc">
                <el-row>
                    <el-col :span="5" style="padding-left: 10px; padding-right: 10px;">
                        <div class="left-part" >

                            <el-row style="margin-bottom:20px; ">
                                <el-col :span="24">
                                    <el-input v-model="filterText" size ="mini" placeholder="姓名或学号" ></el-input>
                                </el-col>
<!--                                <el-col :span="12">-->
<!--                                    <el-input v-model="rightForm.searchText" placeholder="姓名或学号"  @input='' @keyup.enter.native="loadRightList(null)"></el-input>-->
<!--                                </el-col>-->
<!--                                <el-col :span="12">-->
<!--                                    <el-input v-model="rightForm.batchId" placeholder="批次号"  @input='' @keyup.enter.native="loadRightList(null)"></el-input>-->
<!--                                </el-col>-->
<!--                                <el-col :span="12">-->
<!--                                    <el-input v-model="rightForm.seq" placeholder="序号"  @input='' @keyup.enter.native="loadRightList(null)"></el-input>-->
<!--                                </el-col>-->
<!--                                <el-col :span="12">-->
<!--                                    <el-button type="primary" size ="mini" icon="search" @click='loadRightList(null)'>查询</el-button>-->
<!--                                </el-col>-->
                            </el-row>
                            <el-row class="data-row">
                                <el-tree class="filter-tree"  :data="rightTreeData" :props="defaultProps" :filter-node-method="filterNode" ref="rightTree">
                                    <div class="custom-tree-node" slot-scope="{ node, data }">
                                        <el-button type="text" v-if="data.isPre" size ="mini" @click='openRight(data.myPre)'>{{ node.label }}</el-button>
                                        <span v-else :style="{fontSize: data.isRoot?'14px':'12px', color: data.isRoot?'black':'red'}" >{{ node.label }}</span>
                                    </div>
                                </el-tree>
                            </el-row>
                        </div>

                    </el-col>
                    <el-col :span="19" >
                        <rightPage v-if="selRight!=null" ref="rightPage" :info="selRight" @ok="examChange" @changeExam="examChange"></rightPage>
                    </el-col>
                </el-row>

            </el-tab-pane>

            <el-tab-pane label="入库检查" name="ruku">
                <el-row>
                    <el-col :span="4" style="padding-left: 10px; padding-right: 10px;">
                        <el-button type="primary" size ="mini" icon="search" @click='rukuCheck'>查询</el-button>
                    </el-col>
                    <el-col :span="20">
                        <span v-if="rukuCountList.length>0" v-for="item in rukuCountList"
                              :class="(item.status=='ZC'|| item.status=='RK')?'':'error'"
                        >{{formatStatus(item.status)}}:{{item.status_count}},</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" style="padding-left: 10px; padding-right: 10px;">
                        <div class="table_container">
                            <el-table v-loading="rukuloading" :data="rukuData" style="width: 100%" align='center'>

                                <el-table-column prop="batch_id" label="批次号" align='center' ></el-table-column>
                                <el-table-column prop="seq" label="序号" align='center' ></el-table-column>
                                <el-table-column prop="student_no" label="学号"  width="150" align='center' ></el-table-column>
                                <el-table-column prop="student_name" label="姓名" align='center' ></el-table-column>
                                <el-table-column prop="stu_count" label="重复次数" align='center' ></el-table-column>
                                <el-table-column prop="page_no" label="页码" align='center' >
                                    <template slot-scope='scope'>
                                        <el-input v-model="scope.row.page_no" ></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="status" label="状态" align='center' width="80" >
                                    <template slot-scope='scope'>
                                        <el-input v-model="scope.row.status" ></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="operation" align='center' label="操作" width="300">
                                    <template slot-scope='scope'>
                                        <el-button icon='edit' v-if="scope.row.status=='ZC'" size="mini" @click='viewDulStudent(scope.row)'>查看</el-button>
                                        <el-button type="danger" size="mini" @click="rerun(scope.row)" >更新</el-button>
<!--                                        <el-button type="danger" size="mini" @click="updatePageNo(scope.row)" >更新页码</el-button>-->
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-if="isCanRK">
                    <el-col :span="24" style="padding-left: 10px; padding-right: 10px;">
                        <el-button type="primary"  @click='ruku'>核对无误，入库</el-button>
                    </el-col>
                </el-row>
            </el-tab-pane>
        </el-tabs>
        <el-image-viewer v-if="showViewer && examImageList" :on-close="closeViewer" :url-list="examImageList" />


    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import { saveAs } from 'file-saver';
    import * as simpleApi from "request/simple";
    import waitPage from "./waitPage";
    import exceptPage from "./exceptPage";
    import ocrExceptPage from "./ocrExceptPage";
    import rightPage from "./rightPage"
    import examImageEditor from './examImageEditor'
    import posExceptPage from "./posExceptPage";
    import XEUtils from "xe-utils";
    import Pagination from "@/components/pagination";
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

    export default {
        name: 'scanPre',
        data(){
            return {
                tagName:'dsb', rukuloading: false, showViewer: false,
                tableData: [], tableHeight:0, examList: [], rukuData: [], rukuCountList: [],
                examCount: { notPre: 0, preing: 0, exceptY: 0, ocring: 0, except: 0, ocred: 0, rk:0, wait: 0, success: 0},
                loading:false, isShow:false, isCanRK:false, isShowReOcrSY: false, activeName: '1',
                selWait: null, selExcept: null, selOcrExcept: null, selRight: null, selExamExcept: null,
                waitList: [], abList: [], examImageList: [], rightTreeData: [], filterText: null,
                loseNoList: [], loseNoY1List: [], wrongOcrList: [], exceptList: [], page1NoFoundList: [], duplicList: [],
                rightList: [], leftStyle: null, examId: null,
                rightForm: { pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, batchId: null, seq: null, },
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, examId: null, schoolId: null},
                defaultProps: {children: 'children', label: 'label'},
            }
        },
        components:{
            Pagination, ElImageViewer, waitPage, exceptPage, rightPage, ocrExceptPage, examImageEditor, posExceptPage
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
        watch: {
            filterText(val) {
                this.$refs.rightTree.filter(val);
            }
        },
        created() {
            let myHeight = document.documentElement.clientHeight ;
            console.log('myHeight:', myHeight);
            this.leftStyle = {height: (myHeight-200) + 'px'};
        },
      	mounted() {
          if (this.$route.query.examId) {
            this.examId = parseInt(this.$route.query.examId,10);
            this.tagName = 'dwyc';
            this.examChange();
          }
            this.loadExamList();
	   },
        methods: {
            tabClick(){

            },
            formatStatus(status){
                return mUtils.examStatus(status);
            },
            getExamName(index, item){
                if(item.student_no != null){
                    return index + "." + item.student_no + "(" + item.seq + ")";
                }
                return index + "." +  item.seq ;
            },
            getExceptName(index, item){
                let status = item.status;
                if(status == "SY") return index + "." + "缺少第一页(" + item.seq+")";
                if(status == "UN") return index + "." + "未知异常(" + item.seq+")";
                if(status == "Y5") return index + "." + "找不到定位点(" + item.seq+")";
                if(status == "Y6") return index + "." + "切图异常(" + item.seq+")";
                if(status == "F1") return index + "." + "第一页已删除(" + item.seq+")";
                if(status == "Y7") return index + "." + "不是这张试卷？(" + item.seq+")";
                // return index + "." + mUtils.strLeft(item.exam_name, 15);
                return index + ".异常";
            },
            examChange(v){
                this.loading = true;
                this.setSelNull();
                this.loadScanCount();
                this.loadABList();
                this.loadRightTree();
                this.loadExceptStudentNotFoundList();
                this.loadExceptStudentLoseList();
                this.loadExceptExamList();
                this.loadExceptStudentRepeatList();
                this.loadPage1NoFoundList();
                this.loadWrongOcrList();
                this.loadWaitingList();
            },
            loadExamList(){

                let search = { licenceId: this.userInfo.licenceId, schoolId: null, teacherId: null};
                let role = this.userInfo.role;
                if(role == "sclAdmin") search["schoolId"] = this.userInfo.schoolId;
                if(role == "teacher") {
                    search["schoolId"] = this.userInfo.schoolId;
                    search["teacherId"] = this.userInfo.id;
                }
                let param = { controllerName: 'scan/scanPre', methodName: '/exceptExamlist', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.examList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadWaitingList(callback){
                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), status: '01', orderBy: 'sf.same_exam_id, sf.crdt'};
                let param = { controllerName: 'scan/scanPre', methodName: '/list', param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadWaitingList", data)
                    if (data.code == 0) {
                        this.waitList = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error", error)
                });
            },
            openWaiting(row){
                this.selWait = row;
            },
            openRight(row){
                this.selRight = row;
            },
            loadRightList(callback){
                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId),
                    searchText: mUtils.searchText(this.rightForm.searchText), batchId: mUtils.searchText(this.rightForm.batchId),
                    seq: mUtils.searchText(this.rightForm.seq),  status: 'ZC', pageNo: 1, orderBy: 'sf.uddt'};
                let param = { controllerName: 'scan/scanPre', methodName: '/list', pageNo: this.rightForm.pageNo, pageSize: this.rightForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadRightList", data)
                    if (data.code == 0) {
                        this.rightList = data.result;
                        this.rightForm.pageNo = data.pageNo;
                        this.rightForm.pageSize = data.pageSize;
                        this.rightForm.totalCount = data.totalCount;
                        console.log("loadRightList", this.rightForm)
                        if(callback) callback(data.result);
                    }
                    this.loading = false;
                }).catch((error) => {
                    console.log("error", error)
                });
            },
            loadRightTree(callback){
                let search = { licenceId: this.licenceId, examId: XEUtils.toNumber(this.examId)};
                let param = { controllerName: 'scan/scanPre', methodName: '/rightTree', param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadRightTree", data)
                    if (data && data.length > 0) {
                        this.rightTreeData = [];
                        for (let i=0; i<data.length; i++){
                            let d = data[i];
                            let o = {label: d.hard_name + "("+d.pre_count +"/" + d.stu_count+")", isRoot: true, children: []};
                            if(("list" in d) && d.list != null ){
                                let childrens = [];
                                for (let j=0; j<d.list.length; j++){
                                    let c = d.list[j];
                                    childrens.push({label: c.nick_name+"("+c.my_no+")", myPre: c.student_no!=null?c:null, isRoot: false, isPre:c.student_no!=null?true:false  });
                                }
                                o.children = childrens;
                            }
                            console.log("my o", o)
                            this.rightTreeData.push(o);
                        }
                        if(callback) callback(data.result);
                    }
                    this.loading = false;
                }).catch((error) => {
                    console.log("error", error)
                });
            },
            filterNode(value, data){
                if((!value) || (value == "")) return true;
                return data.label.indexOf(value) !== -1;
            },
            loadABList(callback){

                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), status: 'AB', pageNo: 1, orderBy: 'sf.uddt'};
                let param = { controllerName: 'scan/scanPre', methodName: '/list', pageSize: 5000, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadABList", data)
                    if (data.code == 0) {
                        this.abList = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },

            loadScanCount(){

                let search = { licenceId: this.userInfo.licenceId, examId: this.examId};
                let param = { controllerName: 'scan/scanPre', methodName: '/scanCount', param: search};
                return simpleApi.post(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data) {
                        this.examCount.notPre = 0;
                        this.examCount.rk = 0;
                        this.examCount.preing = 0;
                        this.examCount.exceptY = 0;
                        this.examCount.ocring = 0;
                        this.examCount.ocred = 0;
                        this.examCount.except = 0;
                        for (let i=0; i<data.length; i++){
                            if(data[i].status == "00" || data[i].status == "20") {
                                this.examCount.notPre = data[i].status_count;
                            } else if(data[i].status == "Y5" || data[i].status == "Y6" || data[i].status == "Y7"){
                                this.examCount.exceptY = this.examCount.exceptY + data[i].status_count;
                            } else if(data[i].status == "01"){
                                this.examCount.preing = data[i].status_count;
                            }else if(data[i].status == "SB" || data[i].status == "2B" || data[i].status == "C1" || data[i].status == "2C") {
                                this.examCount.ocring = data[i].status_count;
                            }else if(data[i].status == "RK") {
                                this.examCount.rk = data[i].status_count;
                            }else if(data[i].status == "ZC") {
                                this.examCount.ocred = data[i].status_count;
                            }else {
                                this.examCount.except = this.examCount.except+data[i].status_count;
                            }
                        }
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadExceptStudentNotFoundList(callback){

                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), status: 'Y1', pageNo: 1, orderBy: 'sf.uddt'};
                let param = { controllerName: 'scan/scanPre', methodName: '/list', pageSize: 5000, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExceptStudentNotFoundList", data)
                    if (data.code == 0) {

                        this.loseNoY1List = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadExceptStudentLoseList(callback){
                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), pageNo: 1, status: 'Y4', orderBy: 'sf.uddt'};
                let param = { controllerName: 'scan/scanPre', methodName: '/list', pageSize: 5000, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExceptStudentLoseList", data)
                    if (data.code == 0) {
                        this.loseNoList = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadExceptExamList(callback){

                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), inStatus: ['Y5', 'Y6', 'Y7']};
                let param = { controllerName: 'scan/scanPre', methodName: '/list', pageSize: 5000, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExceptExamList", data)
                    if (data.code == 0) {
                        this.exceptList = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error", error)
                });
            },
            loadExceptStudentRepeatList(callback){

                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), status: 'Y9', pageNo: 1, orderBy: 'sf.student_no, sf.uddt'};
                let param = { controllerName: 'scan/scanPre', methodName: '/list', pageSize: 5000, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExceptStudentRepeatList", data)
                    if (data.code == 0) {
                        this.duplicList = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadPage1NoFoundList(callback){

                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), status: "F1", orderBy: 'sf.uddt'};
                let param = { controllerName: 'scan/scanPre', methodName: '/list', pageSize: 5000, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadPage1NoFoundList", data)
                    if (data.code == 0) {
                        let list = data.result;
                        if(list){
                            this.page1NoFoundList = list;
                            if(callback) callback(data.result);
                        }
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadWrongOcrList(callback){

                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), status: "TT", orderBy: 'sf.uddt'};
                let param = { controllerName: 'scan/scanPre', methodName: '/list', pageSize: 5000, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadWrongOcrList", data)
                    if (data.code == 0) {
                        this.wrongOcrList = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            nextStudentNoLost(){
                console.log('nextStudentNoLost', this.selExcept)
                let status = this.selExcept.status;
                if(status == 'Y1') this.loadExceptStudentNotFoundList(this.nextStudentCallback);
                if(status == 'Y4') this.loadExceptStudentLoseList(this.nextStudentCallback);
                if(status == 'Y9') this.loadExceptStudentRepeatList(this.nextStudentCallback);
                if(status == 'SY' || status == 'F1') this.loadPage1NoFoundList(this.nextStudentCallback);
                if(status == 'AB') this.loadABList(this.nextStudentCallback);
                if(status == 'Y5' || status == 'Y6' || status == 'Y7') this.loadExceptExamList(this.nextStudentCallback);

            },
            nextStudentCallback(list){
                if(list && list.length > 0){
                    this.openStudentNoLost(list[0]);
                }else{
                    this.setSelNull();
                }
                // this.examChange();
            },
            nextExamExcept(){
                let that = this;
                this.loadExceptExamList( function (list) {
                    console.log("nextExamExcept", list)
                    if(list && list.length > 0){
                        that.openExamExcept(list[0]);
                    }else{
                        that.setSelNull();
                    }
                });
            },
            nextAnswerExcept(){
                let that = this;
                this.loadWrongOcrList( function (list) {
                    if(list && list.length > 0){
                        that.openAnswerNotClear(list[0]);
                    }else{
                        that.setSelNull();
                    }
                });
            },
            collapseChange(v){
                let examId = mUtils.searchText(this.examId);
                if(examId == null){
                    this.$message({message: '请选择考试！', type: 'warning'});
                    return;
                }
            },
            downloadImage(item) {
                item.oss_full_name = item.oss_full_name.replace("http:", "https:");
                saveAs(item.oss_full_name, "image.png");
            },
            setSelNull(){
                this.selWait = null;
                this.selExcept = null;
                this.selOcrExcept = null;
                this.selRight = null;
                this.selExamExcept = null;
            },
            openStudentNoLost(item){
                this.setSelNull();
                console.log("openStudentNoLost", item)
                this.selExcept = item;
            },

            openExamExcept(item){
                this.setSelNull();
                this.selExamExcept = item;
            },

            openAnswerNotClear(row){
                this.setSelNull();
                this.selOcrExcept = row;
            },
            rukuCheck(){
                this.rukuloading = true;
                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId),};
                let param = { controllerName: 'scan/scanPre', methodName: '/rukuCheck', pageNo: 1, pageSize: 90000, param: search};
                return simpleApi.post(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data) {
                        this.rukuCountList = data.countList;
                        let duplicList = data.duplicList;
                        let myArr = [];
                        if(duplicList && duplicList.length > 0){
                            myArr = duplicList;
                        }
                        let errorList = data.errorList;
                        if(errorList && errorList.length > 0){
                            myArr = [...myArr, ...errorList];
                        }
                        let nullList = data.nullList;
                        if(nullList && nullList.length > 0){
                            myArr = [...myArr, ...nullList];
                        }
                        this.rukuData = myArr;
                        if(this.rukuCountList){
                            let is_rk = true;
                            for (let i=0; i<this.rukuCountList.length; i++){
                                let r = this.rukuCountList[i];
                                if(r.status == "ZC" || r.status == "RK") continue;
                                if(r.status_count > 0){
                                    is_rk = false;
                                    break;
                                }
                            }
                            this.isCanRK = is_rk;
                        }
                    }
                    this.rukuloading = false;
                }).catch((error) => {
                    this.rukuloading = false;
                    console.log("error", error)
                });
            },
            ruku(){
                let that = this;
                this.$confirm('一旦入库将无法恢复，确认入库?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                    this.rukuing = true;
                    let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), status: 'ZC', newStatus: 'RK'};
                    let param = { controllerName: 'scan/scanPre', methodName: '/updateByStatusChange', param: search};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '入库成功!'});
                            that.rukuCheck();
                        }else{
                            that.$message({ type: 'error', message: '入库失败!'});
                        }
                        that.rukuing = false;
                    });
                }).catch((e) => {
                    that.rukuing = false;
                    console.log("error", e)
                });
            },
            viewDulStudent(item){
                this.tagName = 'zc';
                this.rightForm.searchText = item.student_no;
                this.loadRightList();
            },
            onBottom(item){
                let that = this;
                let status = item.status;
                let param = { controllerName: 'scan/scanPre', methodName: '/updateLastTime', param: {id: item.id}};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    if (code == 0) {
                        that.$message({ type: 'success', message: '设置成功!'});
                        if(status == 'Y1') that.loadExceptStudentNotFoundList();
                        if(status == 'Y4') that.loadExceptStudentLoseList();
                        if(status == 'Y9') that.loadExceptStudentRepeatList();
                        if(status == 'TT') that.loadWrongOcrList();
                        if(status == 'SY'||status == 'F1') that.loadPage1NoFoundList();
                        if(status == 'Y5' || status == 'Y6' || status == 'Y7') that.loadExceptExamList();
                    }
                });
            },
            onDeleteScanFile(item){
                let that = this;

                let status = item.status;

                this.doDeleteScanFile(item, function () {
                    // that.examChange();
                    if(status == 'Y1') that.loadExceptStudentNotFoundList(that.nextStudentCallback);
                    if(status == 'Y4') that.loadExceptStudentLoseList(that.nextStudentCallback);
                    if(status == 'Y9') that.loadExceptStudentRepeatList(that.nextStudentCallback);
                    if(status == 'SY' || status == 'F1') that.loadPage1NoFoundList(that.nextStudentCallback);
                    if(status == 'Y5' || status == 'Y6' || status == 'Y7') that.loadExceptExamList(that.nextStudentCallback);
                });

            },
            doDeleteScanFile(item, callback){
                let that = this;
                this.$confirm('将删除此试卷?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'scan/scanPre', methodName: '/deleteBySameExamId', param: { sameExamId: item.same_exam_id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            if(callback) callback();
                        }
                    });
                }).catch(() => {

                });
            },
            rerun(row){
                let id = row.id;
                let status = row.status;
                let that = this;
                this.$confirm('此操作将更新试卷状态, 是否继续?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'adminScan', methodName: '/updatePreStatus', param: { id: id, status: status, pageNo: row.page_no }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '更新成功!'});
                            that.rukuCheck();
                        }
                    });
                }).catch(() => {

                });
            },
            updatePageNo(row){
                let param = { controllerName: 'scan/scanPre', methodName: '/updateById',
                    param: { id: row.id, pageNo: row.page_no }};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    if (code == 0) {
                        this.$message({ type: 'success', message: '设置成功!'});
                        this.rukuCheck();
                    }else{
                        this.$message({ type: 'success', message: '设置失败!'});
                    }
                });
            },
            closeViewer(){
                this.showViewer = false;
            },
            openViewer(row){
                console.log(row)
                this.examImageList = [];
                if(row.oss_full_name != null){
                    this.examImageList.push(row.oss_full_name);
                }
                this.showViewer = true;
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .scanPre{
        padding: 10px;
        .search_container{

        }
        .error {
            color: red;
        }
        .collapse-title{
            font-size: 16px;
            font-weight: bold;
            margin-left: 20px;
        }
        .left-part {
            padding: 10px;
            border: 1px solid rgba(203, 202, 202, 0.71);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            .data-row{
                margin-bottom: 10px;
                text-align: left;
            }
        }
        .right-part {
            padding: 10px;
            border: 1px solid rgba(203, 202, 202, 0.71);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            .data-row{
                margin-bottom: 10px;
            }
            .student-no-lost {
                text-align: left;
            }
        }
        .btnRight{
            float: right;
            margin-right: 0px !important;
        }
        .searchArea{
            background:rgba(255,255,255,1);
            border-radius:2px;
            padding: 18px 18px 0;
        }
        .table_container{
            padding: 10px;
            background: #fff;
            border-radius: 2px;
        }
        .el-dialog--small{
           width: 600px !important;
        }
        .pagination{
            text-align: left;
            margin-top: 10px;
        }
    }

</style>


