<template>
  <div class="rightPage" >

    <el-row>
      <el-col :span="20">
        <el-row v-show="tableData && tableData.length > 0"  @click="openViewer">
          <el-col :span="24">
            <canvas id="myCanvas1" width="1000" height="1000"></canvas>
          </el-col>
        </el-row>
        <el-row v-show="tableData && tableData.length > 1"  @click="openViewer">
          <el-col :span="24">
            <canvas id="myCanvas2" width="1000" height="1000"></canvas>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <div class="right-part" :style="myStyle">
          <el-row class="base-info" >
            <el-col :span="24">
              基本信息
            </el-col>
            <el-col :span="24" style="white-space:nowrap;">
              批次号：{{scanPre.batch_id}}
            </el-col>
            <el-col :span="24">
              序号：{{scanPre.seq}}
            </el-col>
            <el-col :span="24">
              页码：{{scanPre.page_no}}
            </el-col>
            <el-col :span="24">
              状态：<span :class="scanPre.status=='ZC'?'': 'status-error'">{{formatStatus(scanPre.status)}}</span>
            </el-col>
            <el-col :span="24">
              学号：{{scanPre.student_no}}
            </el-col>
            <el-col :span="24">
              姓名：{{scanPre.student_name}}
            </el-col>
            <el-col :span="24" v-if="scanPre.paper_ab!=null">
              AB卷：{{scanPre.paper_ab}}卷
            </el-col>
            <el-col :span="24" style="color: #F6F7FC;white-space:nowrap;">
              id：{{scanPre.id}}
            </el-col>
            <el-col :span="24" style="color: #F6F7FC;white-space:nowrap;">
              same id：{{scanPre.same_exam_id}}
            </el-col>
          </el-row>
          <el-row style="text-align: center" class="base-info">
            <el-col :span="24">
              基本操作
            </el-col>

            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click="openViewer">查看原卷</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='showChangeExamDialog()'>更换考试</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='changeStudentNo()'>更改学号</el-button>
            </el-col>

          </el-row>
          <el-row style="margin-top: 20px; text-align: center" class="base-info">
            <el-col :span="24" style="color: red; ">
              危险操作
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="danger" size="mini" @click='doDeleteScanFile()'>删除</el-button>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <studentBox title="选择学生" :scanFileId="scanPre.id" ref="studentBox" @ok="selectStudent" :exceptType="scanPre.status" :updateStatus="'C1'"
                :examId="scanPre.exam_id" :searchText="null" :sameExamId="scanPre.same_exam_id" :top="200" :left="300" :right="0"></studentBox>

    <changeExamDialog ref="changeExamDialog" :scanPre="scanPre" @ok="selectChangeExam"></changeExamDialog>
    <el-image-viewer v-if="showViewer && examImageList" :on-close="closeViewer" :url-list="examImageList" />
  </div>

</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import XEUtils from 'xe-utils'
  import * as simpleApi from "request/simple";
  import * as mUtils from '@/utils/mUtils'
  import studentBox from "./studentBox";
  import changeExamDialog from "./changeExamDialog"
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

  export default {
    name: 'rightPage',
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    props: {
      info: {
        type: Object
      }
    },
    components:{
      studentBox, changeExamDialog, ElImageViewer
    },
    watch: {
      info: {
        deep: true, immediate:true,
        handler(n, o){
          console.log(n)
          if(n){
            this.scanPre = n;
            this.loadExamPages();
          }
        }
      },
    },
    data() {
      return {
        scanPre: null, scanList: [], searchText: '', showViewer: false,
        url: '', id: null, scanFileId: null, examId: null, myStyle: null,
        theDialogVisible: true, loading:false, tableData: [], examImageList: [],
        getPre: null, imgInfo: null, pointList:[], cardInfo: null, selPoint: null,
        searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null},
        percent: {"P2": [[0.37184693, 0.69066076], [0.69066076, 1]], "PD2": [[0.37184693, 0.689799434], [0.689799434, 1]],
          "P3": [[0.2830118, 0.525941187], [0.525941187, 0.761846788], [0.761846788, 1]],
          "P4": [[0.228420257, 0.421617536], [0.421617536, 0.609145881], [0.609145881, 0.798488284], [0.798488284, 1]],
          "P5": [[0.191496103, 0.353653127], [0.353653127, 0.511057601], [0.511057601, 0.669982891], [0.669982891, 0.839300425], [0.839300425, 1]],
          "P6": [[0.164839361, 0.305623739], [0.305623739, 0.442317133], [0.442317133, 0.580319642], [0.580319642, 0.727267769], [0.727267769, 0.866688485], [0.866688485, 1]],
          "P7": [[0.14470408, 0.267333844], [0.267333844, 0.386372342], [0.386372342, 0.506512162], [0.506512162, 0.634504884], [0.634504884, 0.75593756], [0.75593756, 0.871959395], [0.871959395, 1]],
          "P8": [[0.128946919, 0.237625875], [0.237625875, 0.343104625], [0.343104625, 0.449564772], [0.449564772, 0.5630227], [0.5630227, 0.670634921], [0.670634921, 0.773425499], [0.773425499, 0.886883427], [0.886883427, 1]],
          "P9": [[0.116286676, 0.215988456], [0.215988456, 0.312804233], [0.312804233, 0.410505051], [0.410505051, 0.514516595], [0.514516595, 0.613256373], [0.613256373, 0.707647908], [0.707647908, 0.811659452], [0.811659452, 0.915132275], [0.91513227, 1]],
          "P10": [[0.105890185, 0.197974701], [0.197974701, 0.287431234], [0.287431234, 0.377728722], [0.377728722, 0.473772732], [0.473772732, 0.564981254], [0.564981254, 0.652265321], [0.652265321, 0.748309331], [0.748309331, 0.843862784], [0.843862784, 0.922386909], [0.922386909, 1]],
          "P11": [[0.098270031, 0.183727888], [0.183727888, 0.266746878], [0.266746878, 0.350546306], [0.350546306, 0.43967872], [0.43967872, 0.524323621], [0.524323621, 0.605326483], [0.605326483, 0.694458897], [0.694458897, 0.783136056], [0.783136056, 0.856009365], [0.856009365, 0.928004683], [0.928004683, 1]],
          "P12": [[0.091670206, 0.171388704], [0.171388704, 0.24883213], [0.24883213, 0.327003579], [0.327003579, 0.410149851], [0.410149851, 0.489109992], [0.489109992, 0.564672693], [0.564672693, 0.647818965], [0.647818965, 0.730540557], [0.730540557, 0.798519687], [0.798519687, 0.865679791], [0.865679791, 0.932839896], [0.932839896, 1]]
        },
      }
    },
    mounted() {
      let myHeight = document.documentElement.clientHeight ;
      this.myStyle = {height: (myHeight-200) + 'px'};

    },
    destroyed() {

    },
    methods: {

      formatStatus(status){
        return mUtils.examStatus(status);
      },
      loadExamPages(){
        let that = this;
        console.log("loadExamPages")
        let search = { licenceId: this.userInfo.licenceId, sameExamId: this.scanPre.same_exam_id};
        let param = { controllerName: 'scan/scanPre', methodName: '/getBySameExamId',  param: search};
        return simpleApi.list(param).then(({result: {code, data}}) =>  {
          console.log("loadExamPages", data)
          this.examImageList = [];
          if (data) {
            this.tableData = data;
            for (let i=0; i<data.length; i++){
              let d = data[i];
              if(d.ossFullName != null){
                this.examImageList.push(d.ossFullName);
              }
              this.loadCanvas(d);
            }

          }
        }).catch((error) => {
          console.log("error")
        });
      },
      loadCanvas(d){
        let that = this;
        let pageNo = d.pageNo;
        this.$nextTick(() => {
          var canvas = document.getElementById("myCanvas"+pageNo);
          let tt = document.documentElement.clientWidth / 5 *3;
          console.log("loadCanvas pageNo", pageNo, canvas)
          if(canvas.getContext){
            let ctx = canvas.getContext('2d');
            let img = new Image();
            img.src = mUtils.urlAddTimestamp(d.cutOssFullName);
            img.onload = function () {
              let scale = 1;
              if(this.width > tt||this.height > tt){
                if(this.width > this.height) scale = tt/this.width;
                if(this.height > this.width) scale = tt/this.height;
                ctx.width = this.width * scale;
                ctx.height = this.height * scale;
                canvas.width = ctx.width;
                canvas.height = ctx.height;
                // console.log("ctx", ctx.width, ctx.height, scale)
                ctx.drawImage(this, 0, 0, ctx.width, ctx.height);
                that.cardInfoAndImage(ctx, d);
              }
            }
          }
        });

      },
      cardInfoAndImage(ctx, d){
        try{
          // console.log("cardInfoAndImage", d)
          if(d.cardInfo){
            let cardInfo = JSON.parse(d.cardInfo);
            let index = 0;
            let width = ctx.width;
            let height = ctx.height;
            let client = document.getElementById("myCanvas"+d.pageNo).getBoundingClientRect();
            console.log("pageNo", d.pageNo, client);
            if(cardInfo && cardInfo.length > 0){
              for (let i=0; i<cardInfo[d.pageNo-1].sections.length; i++){
                let sect = cardInfo[d.pageNo-1].sections[i];
                for (let j=0; j<sect.list.length; j++){
                  if(sect.list[j].type == "Object"){
                    let pos = sect.list[j].position;
                    let branchs = sect.list[j].branch;
                    // console.log('pos', pos, branchs)
                    for (let k=0; k<branchs.length; k++){
                      let branch = branchs[k];
                      ctx.strokeStyle = 'blue';
                      ctx.lineWidth = 1;
                      let posWidth = pos.width_scale*width;
                      let posHeight = pos.height_scale*height;
                      let x = pos.left_begin*width + posWidth * branch.left_begin;
                      let y = pos.top_begin*height + posHeight * branch.top_begin;
                      let w = posWidth*branch.width_ratio;
                      let h = posHeight*branch.height_ratio;
                      if(d.pageNo==2) console.log('branch...', k, d.pageNo, branch);
                      if("stu_answer" in branch){
                        console.log('branch', k, d.pageNo, branch.stu_answer);
                        ctx.fillStyle = 'red'
                        // ctx.font = 'normal bold 12px Arial,sans-serif '
                        let stu_anw = branch.stu_answer;
                        let numList = branch.numList;
                        let a_h = h / stu_anw.length;
                        for (let m=0; m<stu_anw.length; m++){
                          let a_x = x+5;
                          let a_y = y+10 + m * a_h;
                          ctx.fillText(numList[m] + "."+stu_anw[m], a_x, a_y)
                        }
                      }
                      ctx.strokeRect(x, y, w, h);
                      // console.log('draw', d.pageNo, branch, pos);
                    }
                    this.drawChoose(ctx, height, width, pos, branchs);
                  }

                  index = index + 1;
                }
              }
            }
          }
        }catch (e) {
          console.log("error", e)
        }
      },
      drawChoose(ctx, height, width, pos, branchs){
        // console.log("drawChoose", height, width, pos, branchs)
        let posLeft = pos.left_begin*width;
        let posTop = pos.top_begin*height;
        let posWidth = pos.width_scale*width;
        let posHeight = pos.height_scale*height;
        let row_x = 0, row_y = 0, x = 0, y = 0;
        for(let i=0; i< branchs.length; i++){
          let branch = branchs[i] ;
          let b_t = posHeight * branch.top_begin+posTop;
          let b_l = posWidth * branch.left_begin+posLeft;
          let b_h = posHeight * branch.height_ratio;
          let b_w = posWidth * branch.width_ratio;
          let pos = branch.position;
          let perPre = (branch.subAreaTopicType=="Judge")?"PD":"P";
          let ixList = branch.ixList;
          let ixY = b_t + b_h*2.5/pos.height;
          let tiH = b_h*5/pos.height;
          let percent = this.percent[perPre+branch.chooseCount];
          for (let j=0; j<ixList.length; j++){
            let iY = ixY + j*tiH;
            for (let k=0; k<percent.length; k++){
              let tiX = b_l+b_w*percent[k][0];
              let tiXEnd = b_l+b_w*percent[k][1];
              let tiW = tiXEnd-tiX;
              let tiGe = tiW/4;
              let tiHGe = tiH/4;
              ctx.beginPath();ctx.rect(tiX+tiGe, iY, tiW-tiGe*2, tiH-tiHGe);ctx.stroke();
              // console.log("", j, k, tiX, tiGe, iY, tiH, tiW, tiW-tiGe, tiH-tiHGe )
            }
          }

          row_x = row_x + b_w;
        }

      },
      showChangeExamDialog(){
        this.$refs.changeExamDialog.showDialog();
      },
      selectChangeExam(){
        this.$refs.changeExamDialog.hideDialog();
        this.$message({ type: 'success', message: '更新成功!'});
        this.$emit('changeExam');
      },
      changeStudentNo(){
        this.$refs.studentBox.showDialog();
      },
      selectStudent(sameExamId){
        this.$refs.studentBox.hideDialog();
        this.$message({ type: 'success', message: '更新成功!'});
      },
      changePageNo(){
        let that = this;
        let status = this.scanPre.status;
        this.$confirm('本操作应用于试卷倒反扫描，将改变页码顺序，是否确定操作?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {

          let param = { controllerName: 'scan/scanPre', methodName: '/changePageNo', param: { sameExamId: this.scanPre.same_exam_id, status: '01' }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '变更成功!'});
              this.$emit('ok', status);
            }
          });
        }).catch(() => {

        });
      },
      openViewer(){
        console.log("openViewer")
        this.showViewer = true;
      },
      closeViewer(){
        this.showViewer = false;
      },
      doDeleteScanFile(){
        let that = this;
        let status = this.scanPre.status;
        this.$confirm('将删除此试卷?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {

          let param = { controllerName: 'scan/scanPre', methodName: '/deleteBySameExamId', param: { sameExamId: this.scanPre.same_exam_id }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '删除成功!'});
              this.$emit('ok', status);
            }
          });
        }).catch(() => {

        });
      },
    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

  .rightPage {

    .status-error{
      color: red;
    }
    .el-form-item__label {
      font-size: 16px;
      color: black;
    }
    .right-part {
      padding: 10px;
      border: 1px solid rgba(203, 202, 202, 0.71);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .data-row{
        margin-bottom: 10px;
      }
      .student-no-lost {
        text-align: left;
      }
      .base-info {
        text-align: left;
        margin-top: 10px;
        padding: 5px;
        border: 1px solid rgba(203, 202, 202, 0.71);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

  }




</style>
