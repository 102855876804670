<template>
    <vue-drag-resize class="point" :isActive="true" :isDraggable="true" :isResizable="isResizable" :w="w"
                     @resizestop="resizeStop"
                     :h="h" :x="x" :y="y" :minw="1" :minh="1" v-on:resizing="resize" v-on:dragging="resize"
                     @clicked="myClick">
        <canvas v-if="drawData!=null" :id="'pc'+id" :width="w" :height="h"></canvas>
    </vue-drag-resize>

</template>

<script>
import {mapState, mapMutations, mapGetters} from 'vuex';
import VueDragResize from 'vue-drag-resize';

export default {
    name: 'point',
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    data() {
        return {
            selectElement: '', myStyle: null, squareStyle: {width: '15px', height: '15px', border: '1px solid red'},
            theDialogVisible: true, myData: null,
            pw: 0, ph: 0, px: 0, py: 0,
            percent: {"P2": [[0.37184693, 0.69066076], [0.69066076, 1]], "PD2": [[0.37184693, 0.689799434], [0.689799434, 1]],
              "P3": [[0.2830118, 0.525941187], [0.525941187, 0.761846788], [0.761846788, 1]],
              "P4": [[0.228420257, 0.421617536], [0.421617536, 0.609145881], [0.609145881, 0.798488284], [0.798488284, 1]],
              "P5": [[0.191496103, 0.353653127], [0.353653127, 0.511057601], [0.511057601, 0.669982891], [0.669982891, 0.839300425], [0.839300425, 1]],
              "P6": [[0.164839361, 0.305623739], [0.305623739, 0.442317133], [0.442317133, 0.580319642], [0.580319642, 0.727267769], [0.727267769, 0.866688485], [0.866688485, 1]],
              "P7": [[0.14470408, 0.267333844], [0.267333844, 0.386372342], [0.386372342, 0.506512162], [0.506512162, 0.634504884], [0.634504884, 0.75593756], [0.75593756, 0.871959395], [0.871959395, 1]],
              "P8": [[0.128946919, 0.237625875], [0.237625875, 0.343104625], [0.343104625, 0.449564772], [0.449564772, 0.5630227], [0.5630227, 0.670634921], [0.670634921, 0.773425499], [0.773425499, 0.886883427], [0.886883427, 1]],
              "P9": [[0.116286676, 0.215988456], [0.215988456, 0.312804233], [0.312804233, 0.410505051], [0.410505051, 0.514516595], [0.514516595, 0.613256373], [0.613256373, 0.707647908], [0.707647908, 0.811659452], [0.811659452, 0.915132275], [0.91513227, 1]],
              "P10": [[0.105890185, 0.197974701],
                [0.197974701, 0.287431234], //B
                [0.287431234, 0.377728722],
                [0.377728722, 0.47], //D
                [0.47, 0.55],
                [0.55, 0.65], //F
                [0.65, 0.732],
                [0.732, 0.83], //H
                [0.83, 0.915],
                [0.915, 1]],
              "P11": [[0.098270031, 0.183727888], [0.183727888, 0.266746878], [0.266746878, 0.350546306], [0.350546306, 0.43967872], [0.43967872, 0.524323621], [0.524323621, 0.605326483], [0.605326483, 0.694458897], [0.694458897, 0.783136056], [0.783136056, 0.856009365], [0.856009365, 0.928004683], [0.928004683, 1]],
              "P12": [[0.091670206, 0.170],  //A
                [0.170, 0.245], //B
                [0.245, 0.321],
                [0.321, 0.399], //D
                [0.399, 0.47],
                [0.47, 0.55], //F
                [0.55, 0.63],
                [0.63, 0.70], //H
                [0.70, 0.778],
                [0.778, 0.855], //J
                [0.855, 0.93],
                [0.93, 1]]
            },
        }
    },
    components: {
        VueDragResize
    },
    props: {
        id: String,
        h: {
            type: Number,
            default: 15,
        },
        w: {
            type: Number,
            default: 15,
        },
        x: Number,
        y: Number,
        drawData: {
            type: Object, require: false
        },
        isResizable: Boolean,
    },
    mounted: function () {

    },
    watch: {
        drawData: {
            deep: true, immediate: true,
            handler(n, o) {
                console.log(n)
                if (n) {
                    let that = this;
                    this.pw = this.w; this.ph = this.h; this.myData = n;
                    this.$nextTick(()=>{
                        that.drawTheData();
                    });

                }
            }
        },
    },
    methods: {
        myClick(e) {
            // console.log('point click', e)
            this.$emit('select', this.id);
        },
        resizeStop(newRect) {
            console.log("resizestop", newRect);
            this.drawTheData();
        },
        drawTheData() {
            if(!this.myData || this.myData == null) return;
            var canvas = document.getElementById("pc"+this.id);
            console.log("drawTheData", "pc"+this.id, canvas);
            if (canvas.getContext) {
                let ctx = canvas.getContext('2d');
                ctx.width = this.pw; ctx.height = this.ph;
                ctx.font = 'normal 10px Arial,sans-serif '; ctx.strokeStyle = 'red';
                let myType = this.myData.type, myList = this.myData.list, myPos = this.myData.position;
                let pw = this.pw, ph = this.ph;
                if(myType == "regNo"){
                    // console.log("myData1", myList);
                  let noCount = this.myData.count;
                  console.log("regNo", noCount);
                  let w_g = pw / noCount;
                  let h_g = ph / 10;
                  let g4 = w_g / 4;
                  let h4 = h_g / 4;
                  for (let i = 0; i < noCount; i++) {
                    for (let j=0; j<10; j++){
                      let ix = i * w_g
                      let iy = j * h_g
                      ctx.beginPath();ctx.rect(ix+g4, iy+h4/2, g4*2, h4*2);ctx.stroke();
                    }
                  }
                    // for (let i = 0; i < myList.length; i++) {
                    //     for (let j=0; j<myList[i].length; j++){
                    //         let d = myList[i][j];
                    //         let mcW=d.pcWidth*pw, mcH=d.pcHeight*ph;
                    //         let mcX = d.pcLeft*pw+mcW*0.2, mcY = d.pcTop*ph+mcH*0.2;
                    //         // console.log("myData", d.pcLeft, d.pcTop, mcX, mcY, mcW*0.6, mcH*0.6);
                    //         ctx.beginPath();ctx.rect(mcX, mcY, mcW*0.6, mcH*0.6);ctx.stroke();
                    //     }
                    // }
                }
                if(myType == "Object"){
                  let b_x = 0, b_y = 0;
                  for (let i=0; i<myList.length; i++){
                    let branch = myList[i];
                    let pos = branch.position;
                    let bchWidth = pw * pos.width/myPos.width;
                    let bchHeight = ph * pos.height/myPos.height;
                    let bchX = pw * pos.left/myPos.width;
                    let bchY = ph * pos.top/myPos.height;
                    // console.log("kuai", bchWidth, bchHeight, bchX, bchY);
                    ctx.beginPath();ctx.rect(bchX, bchY, bchWidth, bchHeight);ctx.stroke();
                    let ixList = branch.ixList;
                    let ixX = bchX;
                    let ixY = bchY + bchHeight*2.5/pos.height;
                    let tiH = bchHeight*5/pos.height;
                    let percent = this.percent["P"+branch.chooseCount];
                    for (let j=0; j<ixList.length; j++){
                      let iY = ixY + j*tiH;
                      for (let k=0; k<percent.length; k++){
                        let tiX = bchX+bchWidth*percent[k][0];
                        let tiXEnd = bchX+bchWidth*percent[k][1];
                        let tiW = tiXEnd-tiX;
                        let tiGe = tiW/4;
                        let tiHGe = tiH/4;
                        ctx.beginPath();ctx.rect(tiX+tiGe, iY, tiW-tiGe*2, tiH-tiHGe);ctx.stroke();
                        // console.log("", j, k, tiX, tiGe, iY, tiH, tiW, tiW-tiGe, tiH-tiHGe )
                      }
                    }
                  }
                }

            }
        },
        resize(newRect) {
            // console.log("resize", newRect)
            this.pw = newRect.width;
            this.ph = newRect.height;
            this.py = newRect.top;
            this.px = newRect.left;
            this.$emit('repos', this.id, newRect);
        },
    }
}
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

.point {
    border: solid 1px red;

}


</style>
