<template>
  <div class="exceptPage" >

    <el-row v-if="getPre != null">
      <el-col :span="20">
        <div style="width: 1000px; height: auto">
          <img style="width: 100%; height: 100%" class="zoomImg" :ref="'imgContainer1'" @load="loadImage"
               :src="getPre.status=='Y5'?getPre.ossFullName:getPre.cutOssFullName" @mousewheel="handleZoomImg($event, 'imgContainer1')" />
        </div>
      </el-col>
      <el-col :span="4">
        <div class="right-part" :style="myStyle">
          <el-row class="base-info" >
            <el-col :span="24" style="text-align: center">
              基本信息
            </el-col>

            <el-col :span="24" style="white-space:nowrap;">
              批次号：{{scanPre.batch_id}}
            </el-col>
            <el-col :span="24">
              序号：{{scanPre.seq}}
            </el-col>
            <el-col :span="24">
              页码：{{scanPre.page_no}}
            </el-col>
            <el-col :span="24">
              状态：<span :class="scanPre.status=='ZC'?'': 'status-error'">{{formatStatus(scanPre.status)}}</span>
            </el-col>
            <el-col :span="24">
              学号：{{scanPre.student_no}}
            </el-col>
            <el-col :span="24" v-if="scanPre.status=='AB'">
              AB卷：{{scanPre.paper_ab==null?"未选":scanPre.paper_ab+'卷'}}
            </el-col>
            <el-col :span="24" style="color: #F6F7FC;white-space:nowrap;">
              id：{{scanPre.id}}
            </el-col>
            <el-col :span="24" style="color: #F6F7FC;white-space:nowrap;">
              same id：{{scanPre.same_exam_id}}
            </el-col>
          </el-row>
          <el-row style="text-align: center" class="base-info" v-if="scanPre.status=='AB'">
            <el-col :span="24">
              AB卷操作
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='setPaperAB("A")'>设为A卷</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='setPaperAB("B")'>设为B卷</el-button>
            </el-col>
<!--            <el-col :span="24" style="margin-top: 15px;" v-if="pageLen > 1">-->
<!--              <el-button type="primary" size="mini" @click='changePageNo'>扫反纠正</el-button>-->
<!--            </el-col>-->
          </el-row>
          <el-row style="text-align: center" class="base-info">
            <el-col :span="24">
              基本操作
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='showChangeExamDialog'>更换考试</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='changeStudentNo'>更改学号</el-button>
            </el-col>
<!--            <el-col :span="24" style="margin-top: 15px;" v-if="pageLen > 1">-->
<!--              <el-button type="primary" size="mini" @click='changePageNo'>扫反纠正</el-button>-->
<!--            </el-col>-->
          </el-row>
          <el-row style="margin-top: 20px; text-align: center" class="base-info">
            <el-col :span="24" style="color: red; ">
              危险操作
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="danger" size="mini" @click='saveExceptStatus'>设为定位异常</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="danger" size="mini" @click='doDeleteScanFile()'>删除</el-button>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>


    <studentBox title="选择学生" :scanFileId="scanPre.id" ref="studentBox" @ok="selectStudent" :exceptType="scanPre.status" :updateStatus="'C1'"
                :examId="scanPre.exam_id" :searchText="null" :sameExamId="scanPre.same_exam_id" :top="200" :left="300" :right="0"></studentBox>

    <changeExamDialog ref="changeExamDialog" :scanPre="scanPre" @ok="selectChangeExam"></changeExamDialog>
  </div>

</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import XEUtils from 'xe-utils'
  import * as simpleApi from "request/simple";
  import * as mUtils from '@/utils/mUtils'
  import studentBox from "./studentBox";
  import changeExamDialog from "./changeExamDialog"

  export default {
    name: 'exceptPage',
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    props: {
      info: {
        type: Object
      }
    },
    components:{
      studentBox, changeExamDialog
    },
    watch: {
      info: {
        deep: true, immediate:true,
        handler(n, o){
          console.log(n)
          if(n){
            this.scanPre = n;
            this.loadExamPages();
          }
        }
      },
    },
    data() {
      return {
        scanPre: null, scanList: [], searchText: '',
        url: '', id: null, scanFileId: null, examId: null, myStyle: null,
        theDialogVisible: true, loading:false, tableData: [], pageLen: 1,
        getPre: null, imgInfo: null, pointList:[], cardInfo: null, selPoint: null,
        searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null},
      }
    },
    mounted() {
      let myHeight = document.documentElement.clientHeight ;
      this.myStyle = {height: (myHeight-200) + 'px'};

    },
    destroyed() {

    },
    methods: {
      formatStatus(status){
        return mUtils.examStatus(status);
      },
      // 滚轮放大缩小图片 方法
      handleZoomImg(evt, imgName) {
        // console.log('handleZoomImg', imgName)
        evt = evt || window.event
        if (!evt) evt = window.event
        if (evt.stopPropagation) { // 这是取消冒泡,阻止浏览器滚动事件
          evt.stopPropagation()
        } else {
          evt.cancelBubble = true
        };
        if (evt.preventDefault) { // 这是取消默认行为，要弄清楚取消默认行为和冒泡不是一回事
          evt.preventDefault()
        } else {
          evt.returnValue = false
        };
        let delta = 0
        if (evt.wheelDelta) {
          delta = evt.wheelDelta / 120
          if (window.opera) delta = -delta
        } else if (evt.detail) {
          delta = -evt.detail / 3
        }
        let myImgContainer =  this.$refs[imgName]
        // console.log("imgContainer", myImgContainer)
        if(myImgContainer ){
          let imgContainer = myImgContainer;

          let width = imgContainer.offsetWidth
          let height = imgContainer.offsetHeight
          if (delta > 0) {
            imgContainer.style.width = `${width * 1.1}px`
            imgContainer.style.height = `${height * 1.1}px`
          } else if (delta < 0) {
            imgContainer.style.width = `${width * 0.9}px`
            imgContainer.style.height = `${height * 0.9}px`
          }
        }
      },
      loadExamPages(){
        let that = this;
        console.log("loadExamPages")
        let search = { licenceId: this.userInfo.licenceId, id: this.scanPre.id};
        let param = { controllerName: 'scan/scanPre', methodName: '/getById',  param: search};
        return simpleApi.list(param).then(({result: {code, data}}) =>  {
          console.log("loadExamPages", data)
          if (data) {
            this.getPre = data;
            this.getPre.cutOssFullName = mUtils.urlAddTimestamp(this.getPre.cutOssFullName);
            this.getExamLen(data);
            if(data.status != 'SY') this.changeStudentNo();
          }
        }).catch((error) => {
          console.log("error")
        });
      },
      loadImage(){
        this.cardInfoAndImage(this.getPre)
      },
      getExamLen(data){
        try{
          // that.pointList = [];
          if(data.cardInfo){
            let cardInfo = JSON.parse(data.cardInfo);
            this.pageLen = cardInfo.length;
          }
        }catch (e) {
          console.log("error", e)
        }
      },
      cardInfoAndImage(data){
        let that = this;
        try{
          // that.pointList = [];
          if(data.cardInfo){
            let cardInfo = JSON.parse(data.cardInfo);
            that.$nextTick(() => {
              let wm = this.$refs["imgContainer1"];
              let imgInfo = wm.getBoundingClientRect()
              if(imgInfo && "height" in imgInfo && imgInfo.height > 0){
                that.imgInfo = imgInfo;
                that.anaInfo(cardInfo);
              }else{
                setTimeout(that.anaInfo(cardInfo), 500);
              }
            });
          }
        }catch (e) {
          console.log("error", e)
        }
      },
      anaInfo(cardInfo){
        let index = 0;
        if(!this.imgInfo || !"height" in this.imgInfo || this.imgInfo.height ==0){
          this.imgInfo = this.$refs["imgContainer1"].getBoundingClientRect();
        }
        console.log("card", cardInfo, this.imgInfo);
        let {height, left, right, top, width,} = this.imgInfo;
        if(cardInfo && cardInfo.length > 0){

          // for (let i=0; i<cardInfo[this.getPre.pageNo-1].sections.length; i++){
          //   let sect = cardInfo[this.getPre.pageNo-1].sections[i];
          //   for (let j=0; j<sect.list.length; j++){
          //     let pos = sect.list[j].position;
          //     pos["id"] = "id-"+index;
          //     let p = {id: "id-"+index, w: pos.width_scale*width, h: pos.height_scale*height, x: pos.left_begin*width, y:pos.top_begin*height
          //       , ws: pos.width_scale, hs: pos.height_scale, xb: pos.left_begin, yb:pos.top_begin, isR: false};
          //     console.log(p.id, p.x, p.y, p.w, p.h);
          //     this.pointList.push(p);
          //     index = index + 1;
          //   }
          // }
        }
        this.cardInfo = cardInfo;
        // console.log("pointList", this.cardInfo, this.pointList);
      },
      showChangeExamDialog(){
        this.$refs.changeExamDialog.showDialog();
      },
      selectChangeExam(){
        this.$refs.changeExamDialog.hideDialog();
        this.$message({ type: 'success', message: '更新成功!'});
        this.$emit('changeExam');
      },
      changeStudentNo(){
        console.log("changeStudentNo", this.getPre.studentNo)
        this.$refs.studentBox.showDialog(this.getPre.studentNo);
      },
      selectStudent(sameExamId){
        this.$refs.studentBox.hideDialog();
        this.$message({ type: 'success', message: '更新成功!'});
        this.$emit('next', this.getPre.status, sameExamId);
      },
      setPaperAB(v){
        let that = this;
        let param = { controllerName: 'scan/scanPre', methodName: '/updateBySameExamId',
          param: { status: 'SB', paperAb: v, sameExamId: this.getPre.sameExamId}};
        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
          if (code == 0) {
            that.$message({ type: 'success', message: '设置成功!'});
            this.$emit('ok', "AB");
          } else{
            that.$message({ type: 'error', message: '设置失败！'});
            return ;
          }
        });
      },
      changePageNo(){
        let that = this;
        let status = this.getPre.status;
        this.$confirm('本操作应用于试卷倒反扫描，将改变页码顺序，是否确定操作?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {

          let param = { controllerName: 'scan/scanPre', methodName: '/changePageNo', param: { sameExamId: this.getPre.sameExamId, status: '01' }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '变更成功!'});
              this.$emit('ok', status);
            }
          });
        }).catch(() => {

        });
      },
      saveExceptStatus(){
        let that = this;
        let status = this.getPre.status;
        this.$confirm('将此试卷设置为定位异常卷?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {
          let param = { controllerName: 'scan/scanPre', methodName: '/updateById',
            param: { id: that.getPre.id, status: "Y5" }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '设置成功!'});
              that.$emit('ok', status);
            }else{
              that.$message({ type: 'success', message: '设置失败!'});
            }
          });
        }).catch(() => {

        });

      },
      doDeleteScanFile(){
        let that = this;
        let status = this.getPre.status;
        this.$confirm('将删除此试卷?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {

          let param = { controllerName: 'scan/scanPre', methodName: '/deleteBySameExamId', param: { sameExamId: this.getPre.sameExamId }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '删除成功!'});
              this.$emit('ok', status);
            }
          });
        }).catch(() => {

        });
      },
    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

  .exceptPage {

    .status-error{
      color: red;
    }
    .el-form-item__label {
      font-size: 16px;
      color: black;
    }
    .right-part {
      padding: 10px;
      border: 1px solid rgba(203, 202, 202, 0.71);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .data-row{
        margin-bottom: 10px;
      }
      .student-no-lost {
        text-align: left;
      }
      .base-info {
        text-align: left;
        margin-top: 10px;
        padding: 5px;
        border: 1px solid rgba(203, 202, 202, 0.71);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

  }




</style>
