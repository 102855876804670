<template>
  <div class="examImageEditor" ref="imageEditor" v-loading="loading">

    <el-row v-if="scanPre != null">
    <el-col :span="20" style="height: 1000px;">
      <ImageEditor id="tui-image-editor1" ref="tuiImageEditor1" :include-ui="useDefaultUI" :options="options"></ImageEditor>
    </el-col>
    <el-col :span="4">
      <div class="right-part" :style="myStyle">
        <el-row class="base-info" >
          <el-col :span="24">
            基本信息
          </el-col>
          <el-col :span="24" style="white-space:nowrap;">
            批次号：{{scanPre.batch_id}}
          </el-col>
          <el-col :span="24">
            序号：{{scanPre.seq}}
          </el-col>
          <el-col :span="24">
            页码：{{scanPre.page_no}}
          </el-col>
          <el-col :span="24">
            状态：<span :class="scanPre.status=='ZC'?'': 'status-error'">{{formatStatus(scanPre.status)}}</span>
          </el-col>
          <el-col :span="24" style="color: #F6F7FC;white-space:nowrap;">
            id：{{scanPre.id}}
          </el-col>
          <el-col :span="24" style="color: #F6F7FC;white-space:nowrap;">
            same id：{{scanPre.same_exam_id}}
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px; text-align: center" class="base-info">
          <el-col :span="24">
            基本操作
          </el-col>
          <el-col :span="24" style="margin-top: 15px;">
            <el-button type="primary" size="mini" @click='showChangeExamDialog()'>更换考试</el-button>
          </el-col>
          <el-col :span="24" style="margin-top: 15px;">
            <el-button type="primary" size="mini" @click='changeStudentNo()'>更改学号</el-button>
          </el-col>

        </el-row>
        <el-row style="margin-top: 20px; text-align: center" class="base-info">
          <el-col :span="24" style="color: red; ">
            危险操作
          </el-col>
          <el-col :span="24" style="margin-top: 15px;">
            <el-button type="danger" size="mini" @click='doDeleteScanFile()'>删除</el-button>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;" class="base-info">
          <el-col :span="24" style="text-align: center">
            操作
          </el-col>
          <el-col :span="24" style="margin-top: 20px;text-align: center">
            <el-button type="primary" size="mini" @click='uploadImg'>完成并上传</el-button>
          </el-col>
          <el-col :span="24" style="margin-top: 20px;text-align: center">
            <el-button type="primary" size="mini" @click='downloadImage'>下载</el-button>
          </el-col>
          <el-col :span="24" style="margin-top: 20px;text-align: center">
            <el-upload class="upload-demo" :action="uploadUrl" :headers="uploadHeader" :limit="2" :on-change="handleChange" :auto-upload="true">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                上传后系统会重新识别 <br/>
                只能上传png文件，且不超过2M
              </div>
            </el-upload>
          </el-col>
        </el-row>
      </div>
    </el-col>
    </el-row>
    <studentBox title="选择学生" :scanFileId="scanPre.id" ref="studentBox" @ok="selectStudent" :exceptType="scanPre.status" :updateStatus="'C1'"
                :examId="scanPre.exam_id" :searchText="null" :sameExamId="scanPre.same_exam_id" :top="200" :left="300" :right="0"></studentBox>

    <changeExamDialog ref="changeExamDialog" :scanPre="scanPre" @ok="selectChangeExam"></changeExamDialog>
  </div>
</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import XEUtils from "xe-utils";
  import * as simpleApi from "request/simple";
  import "tui-image-editor/dist/tui-image-editor.css";
  import studentBox from "./studentBox";
  import changeExamDialog from "./changeExamDialog"
  import * as mUtils from '@/utils/mUtils'
  // 引入组件
  import ImageEditor from "@toast-ui/vue-image-editor/src/ImageEditor.vue";
  const locale_zh = {
    // override default English locale to your custom
    Crop: "裁剪",
    DeleteAll: "全部删除",
    Delete: "删除",
    Undo: "撤销",
    Redo: "反撤销",
    Reset: "重置",
    Flip: "镜像",
    Rotate: "旋转",
    Draw: "画",
    Shape: "形状标注",
    Icon: "图标标注",
    Text: "文字标注",
    Mask: "遮罩",
    Filter: "滤镜",
    Bold: "加粗",
    Italic: "斜体",
    Underline: "下划线",
    Left: "左对齐",
    Center: "居中",
    Right: "右对齐",
    Color: "颜色",
    "Text size": "字体大小",
    Custom: "自定义",
    Square: "正方形",
    Apply: "应用",
    Cancel: "取消",
    "Flip X": "X 轴",
    "Flip Y": "Y 轴",
    Range: "区间",
    Stroke: "描边",
    Fill: "填充",
    Circle: "圆",
    Triangle: "三角",
    Rectangle: "矩形",
    Free: "曲线",
    Straight: "直线",
    Arrow: "箭头",
    "Arrow-2": "箭头2",
    "Arrow-3": "箭头3",
    "Star-1": "星星1",
    "Star-2": "星星2",
    Polygon: "多边形",
    Location: "定位",
    Heart: "心形",
    Bubble: "气泡",
    "Custom icon": "自定义图标",
    "Load Mask Image": "加载蒙层图片",
    Grayscale: "灰度",
    Blur: "模糊",
    Sharpen: "锐化",
    Emboss: "浮雕",
    "Remove White": "除去白色",
    Distance: "距离",
    Brightness: "亮度",
    Noise: "噪音",
    "Color Filter": "彩色滤镜",
    Sepia: "棕色",
    Sepia2: "棕色2",
    Invert: "负片",
    Pixelate: "像素化",
    Threshold: "阈值",
    Tint: "色调",
    Multiply: "正片叠底",
    Blend: "混合色",
    // etc...
  };
  const customTheme = {
    // image 坐上角度图片
    "common.bi.image": "https://autoexam.happygogo.site/img/logo_small.cf537c24.png", // 在这里换上你喜欢的logo图片
    "common.bisize.width": "0px",
    "common.bisize.height": "0px",
    "common.backgroundImage": "none",
    "common.backgroundColor": "#f3f4f6",
    "common.border": "1px solid #444",

    // header
    "header.backgroundImage": "none",
    "header.backgroundColor": "#f3f4f6",
    "header.border": "0px",

    // load button
    "loadButton.backgroundColor": "#fff",
    "loadButton.border": "1px solid #ddd",
    "loadButton.color": "#222",
    "loadButton.fontFamily": "NotoSans, sans-serif",
    "loadButton.fontSize": "12px",
    "loadButton.display": "none", // 可以直接隐藏掉

    // download button
    "downloadButton.backgroundColor": "#fdba3b",
    "downloadButton.border": "1px solid #fdba3b",
    "downloadButton.color": "#fff",
    "downloadButton.fontFamily": "NotoSans, sans-serif",
    "downloadButton.fontSize": "12px",
    "downloadButton.display": "none", // 可以直接隐藏掉

    // icons default
    "menu.normalIcon.color": "#8a8a8a",
    "menu.activeIcon.color": "#555555",
    "menu.disabledIcon.color": "#434343",
    "menu.hoverIcon.color": "#e9e9e9",
    "submenu.normalIcon.color": "#8a8a8a",
    "submenu.activeIcon.color": "#e9e9e9",

    "menu.iconSize.width": "24px",
    "menu.iconSize.height": "24px",
    "submenu.iconSize.width": "32px",
    "submenu.iconSize.height": "32px",

    // submenu primary color
    "submenu.backgroundColor": "#1e1e1e",
    "submenu.partition.color": "#858585",

    // submenu labels
    "submenu.normalLabel.color": "#858585",
    "submenu.normalLabel.fontWeight": "lighter",
    "submenu.activeLabel.color": "#fff",
    "submenu.activeLabel.fontWeight": "lighter",

    // checkbox style
    "checkbox.border": "1px solid #ccc",
    "checkbox.backgroundColor": "#fff",

    // rango style
    "range.pointer.color": "#fff",
    "range.bar.color": "#666",
    "range.subbar.color": "#d1d1d1",

    "range.disabledPointer.color": "#414141",
    "range.disabledBar.color": "#282828",
    "range.disabledSubbar.color": "#414141",

    "range.value.color": "#fff",
    "range.value.fontWeight": "lighter",
    "range.value.fontSize": "11px",
    "range.value.border": "1px solid #353535",
    "range.value.backgroundColor": "#151515",
    "range.title.color": "#fff",
    "range.title.fontWeight": "lighter",

    // colorpicker style
    "colorpicker.button.border": "1px solid #1e1e1e",
    "colorpicker.title.color": "#fff",
  };
  export default {
    name: 'examImageEditor',
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
        userToken: state => state.user.token,
      }),
    },
    components:{
      ImageEditor, studentBox, changeExamDialog
    },
    props: {
      info: {
        type: Object
      }
    },
    watch: {
      info: {
        deep: true, immediate:true,
        handler(n, o){
          console.log(n)
          if(n){
            this.loading = true;
            this.scanPre = n;
            let oss_full_name = n.oss_full_name.replace("http://", "https://");
            this.url = mUtils.urlAddTimestamp(oss_full_name);
            this.scanFileId = n.id;
            let myHost = process.env.NODE_ENV=='production'?window.g.PROD_HOST:window.g.DEV_HOST;
            this.uploadUrl = myHost + 'scan/scanPre/upload?id='+n.id;
            this.uploadHeader["ke-Token"] = this.userToken;
            this.loadExamImage();
          }
        }
      },
    },
    mounted() {
      let myHeight = document.documentElement.clientHeight ;
      this.myStyle = {height: (myHeight-200) + 'px'};
      this.$nextTick(()=>{
        // console.log(this.$refs.tuiImageEditor)
      })
    },
    data() {
      return {
        url: '', scanFileId: null, isCreate: false, loading: false,
        theDialogVisible: true, instance: null, myStyle: null,
        useDefaultUI: true, imgFile: null,
        uploadUrl: null, scanPre: null,
        uploadHeader: {
          'Company-url': 'http://www.szpcyl.com',
          'X-Powered-By': 'raozq',
          'ke-Token': 'noLogin',
          'ke-Domain': 'WB',
          // 'Content-Type':'multipart/form-data'
        },
        options: {
          includeUI: {
            // 默认开启绘图的功能
            // initMenu: "draw",
            // initialEditType: 'markdown',
            // 支持的菜单
            menu: ["crop", // 裁切
              "flip", // 翻转
              "rotate", // 旋转
              "draw", // 添加绘画
              "shape", // 添加形状
              "mask", // 添加覆盖
              ],
            // 菜单位置在下面
            menuBarPosition: "bottom",
            // 默认加载的图片
            locale: locale_zh,
            loadImage: {
              // 图片路径 assets/dashboard/teacher.jpeg
              // path: 'http://vknow-admin.cn-sh2.ufileos.com/exams/%E7%A6%8F%E5%BB%BA%E7%94%9F%E6%80%81%E5%B7%A5%E7%A8%8B%E8%81%8C%E4%B8%9A%E6%8A%80%E6%9C%AF%E5%AD%A6%E6%A0%A1/%E3%80%8A%E5%85%AC%E5%85%B1%E5%9F%BA%E7%A1%80%E7%9F%A5%E8%AF%86%E2%85%A0%E3%80%8B/TEST/2021-04-24(16)/66.png',
              // 图片的名字，可以省略
              name: "sample",
            },
            theme: customTheme,
          },
          cssMaxWidth: 800,
          cssMaxHeight: 600,
          initialEditType: 'markdown',
        },
      }
    },

    methods: {
      formatStatus(status){
        return mUtils.examStatus(status);
      },
      loadExamImage(){
        let that = this;
        console.log("loadExamImage", that.url)
        if(this.$refs.tuiImageEditor1){

        }
        that.options.includeUI.loadImage.path = that.url;
        this.$nextTick(()=>{
          // let url = process.env.NODE_ENV=='production'?window.g.PROD_HOST:window.g.DEV_HOST;
          // url = url + "public/getScanImage?id=" + this.scanFileId;
          that.imgUrlToFile(that.url)
          // let url = "http://vknow-admin.cn-sh2.ufileos.com/exams/%E7%A6%8F%E5%BB%BA%E7%94%9F%E6%80%81%E5%B7%A5%E7%A8%8B%E8%81%8C%E4%B8%9A%E6%8A%80%E6%9C%AF%E5%AD%A6%E6%A0%A1/%E3%80%8A%E5%85%AC%E5%85%B1%E5%9F%BA%E7%A1%80%E7%9F%A5%E8%AF%86%E2%85%A0%E3%80%8B/TEST/2021-04-24(16)/66.png"

        })

      },
      downloadImage() {
        this.url = this.url.replace("http:", "https:");
        saveAs(this.url, "image.png");
      },
      handleChange(file, fileList) {
        console.log(file, fileList);
        if(file && "success" == file.status){
          this.$message({ type: 'success', message: '上传成功!'});
          this.$emit('ok');
        }
      },
      imgUrlToFile(url) {
        let self = this;
        let imgLink = url;
        let tempImage = new Image();
        //如果图片url是网络url，要加下一句代码
        tempImage.crossOrigin = "*";
        tempImage.onload = function() {
          let base64 = self.getBase64Image(tempImage);
          let imgblob = self.base64toBlob(base64);
          console.log("imgUrlToFile", imgblob)
          self.$refs.tuiImageEditor1.invoke('loadImageFromFile', imgblob, "myImage");
          self.loading = false;
        };
        tempImage.src = imgLink;
      },
      getBase64Image(img) {
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);
        let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
        let dataURL = canvas.toDataURL("image/" + ext);
        // canvas.parentNode.removeChild(canvas);
        return dataURL;
      },
      base64toBlob(base64) {
        let arr = base64.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      },

      uploadImg() {
        let that = this;
        this.loading = true;
        try{

          let img64 = this.$refs.tuiImageEditor1.invoke('toDataURL');
          // console.log("img64", img64)
          let param = { controllerName: 'scan/scanPre', methodName: '/base64Upload',
            param: { id: this.scanFileId, base64Data: img64}};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            console.log(code, data);
            if (code == 0) {
              that.$message({ type: 'success', message: '更改成功!'});
              that.$emit('ok');
              that.loading = false;
              return;
            }else{
              that.$message({ type: 'success', message: '更改失败!'});
            }
            that.loading = false;
          }).catch((e1) => {
            console.log("error", e1);
            that.loading = false;
          });
        }catch (e) {
          console.log(e);
          this.loading = false;
        }
      },
      showChangeExamDialog(){
        this.$refs.changeExamDialog.showDialog();
      },
      selectChangeExam(){
        this.$refs.changeExamDialog.hideDialog();
        this.$message({ type: 'success', message: '更新成功!'});
        this.$emit('changeExam');
      },
      changeStudentNo(){
        console.log("changeStudentNo", this.scanPre.student_no)
        this.$refs.studentBox.showDialog(this.scanPre.student_no);
      },
      selectStudent(sameExamId){
        this.$refs.studentBox.hideDialog();
        this.$message({ type: 'success', message: '更新成功!'});
        this.$emit('next', this.scanPre.status, sameExamId);
      },

      doDeleteScanFile(){
        let that = this;
        let status = this.scanPre.status;
        this.$confirm('将删除此试卷?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {

          let param = { controllerName: 'scan/scanPre', methodName: '/deleteBySameExamId', param: { sameExamId: this.scanPre.same_exam_id }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '删除成功!'});
              this.$emit('ok', status);
            }
          });
        }).catch(() => {

        });
      },
    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

  .examImageEditor {
      height: 90vh;
      text-align: center;

    .status-error{
      color: red;
    }
    #tui-image-editor1{
      height: 700px;
    }
    .base-info {
      text-align: left;
      margin-top: 10px;
      padding: 5px;
      border: 1px solid rgba(203, 202, 202, 0.71);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }




</style>
