<template>
    <div class="changeExamDialog">
        <el-dialog :title="myTitle" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">

            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="试卷名称"  @keyup.enter.native='loadExamList'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" @click='search'>查询</el-button>
                </el-form-item>

            </el-form>
            <div class="table_container">
                <el-table v-loading="loading" :data="tableData" style="width: 100%;" max-height="500" align='center'>

                    <el-table-column prop="name" label="试卷名称"  min-width="150" align='center' ></el-table-column>
                    <el-table-column prop="operation" align='center' label="操作" width="300">
                        <template slot-scope='scope'>
                            <el-button type="primary" size="mini" @click='selectExam(scope.row)'>选择</el-button>
                        </template>
                    </el-table-column>

                </el-table>

            </div>
            
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关  闭</el-button>

            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'


    export default {
        name: 'changeExamDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '缺考学生',
                tableData: [], tableHeight:0,
                isShow:false,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, examId: null, scanId:null, sameExamId: null, },
                dialogVisible:false, loading: false, examId: 0,

            }
        },
        components:{

        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            scanPre: {
                type: Object
            }
        },
        watch: {
            scanPre: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log("scanPre", n)
                    if(n){
                        this.searchForm.scanId = n.id;
                        this.searchForm.sameExamId = n.same_exam_id;
                        this.loadExamList();
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },

            search(){
                this.loadExamList();
            },
            loadExamList(){

                let search = { licenceId: this.userInfo.licenceId, searchText: mUtils.searchText(this.searchForm.searchText),};
                let param = { controllerName: 'exam', methodName: '/examList', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },

            selectExam(row){
                let that = this;
                let status = "00";
                let param = { controllerName: 'scan/scanPre', methodName: '/updateBySameExamId',
                    param: { status: status, examId: row.id, sameExamId: this.searchForm.sameExamId}};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    if (code == 0) {
                        that.$message({ type: 'success', message: '更改关联试卷成功!'});
                        that.tableData = [];
                        that.searchForm.searchText = null;
                        that.dialogVisible = false;
                        that.$emit('ok');
                    } else{
                        that.$message({ type: 'error', message: '关联试卷失败！'});
                        return ;
                    }
                });

            },
            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadStudentList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadStudentList()
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .changeExamDialog{
        .my-row{
            padding: 10px;
        }

        .head-warning {
            margin-top: 20px;
            color: red;
        }

    }


</style>


