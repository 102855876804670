<template>
  <div class="posExceptPage"  >
    <el-row v-if="getPre != null" style="justify-items: center;align-items: center;justify-content: center; margin-bottom: 10px;">
      <el-col :span="3">
        <el-button icon="el-icon-refresh-left" size="mini" circle @click="xuanzhuan(-1)"></el-button>
        <el-button icon="el-icon-refresh-right" size="mini" circle @click="xuanzhuan(1)"></el-button>
      </el-col>
      <el-col :span="3">
        <span>定位点宽度：</span>
      </el-col>
      <el-col :span="2">
        <el-input v-model="posWSize" size="mini" @change="anaInfo"></el-input>
      </el-col>
      <el-col :span="2">
        <span>长度：</span>
      </el-col>
      <el-col :span="2">
        <el-input v-model="posHSize" size="mini" @change="anaInfo"></el-input>
      </el-col>
      <el-col :span="3">
        <span>旋转：{{scaleDu*90}} 度</span>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" size="mini" @click="saveOne">定位单张</el-button>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" size="mini" @click="saveAll">全部定位</el-button>
      </el-col>
    </el-row>
    <el-row v-if="getPre != null">
      <el-col :span="20">
        <div :style="{width: canvasWidth+'px'}" ref="canvasDiv1" id="canvasDiv">
          <canvas id="myCanvas1" ref="canvas1" :width="canvasWidth" :height="canvasHeight"></canvas>
<!--          <img :style="{width: imgWidth+'px'}" ref="waitImg" id="myImage" :src="getPre.ossFullName" @load="loadImage"/>-->
          <point :w="item.w" :h="item.h" :x="item.x" :y="item.y" :id="item.id" v-for="item in pointList"
                 :drawData="item.drawData" :isResizable="false" @select="selectPoint" @repos="changePoint"></point>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="right-part" :style="myStyle">
          <el-row class="base-info" >
            <el-col :span="24">
              基本信息
            </el-col>
            <el-col :span="24" style="white-space:nowrap;">
              批次号：{{scanPre.batch_id}}
            </el-col>
            <el-col :span="24">
              序号：{{scanPre.seq}}
            </el-col>
            <el-col :span="24">
              页码：{{scanPre.page_no}}
            </el-col>
            <el-col :span="24">
              状态：<span :class="scanPre.status=='ZC'?'': 'status-error'">{{formatStatus(scanPre.status)}}</span>
            </el-col>
            <el-col :span="24" style="color: #F6F7FC;white-space:nowrap;">
              id：{{scanPre.id}}
            </el-col>
            <el-col :span="24" style="color: #F6F7FC;white-space:nowrap;">
              same id：{{scanPre.same_exam_id}}
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px; text-align: center" class="base-info">
            <el-col :span="24">
              基本操作
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='showChangeExamDialog()'>更换考试</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='changeStudentNo()'>更改学号</el-button>
            </el-col>

          </el-row>
          <el-row style="margin-top: 20px; text-align: center" class="base-info">
            <el-col :span="24" style="color: red; ">
              危险操作
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="danger" size="mini" @click='doDeleteScanFile()'>删除</el-button>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px;" class="base-info">
            <el-col :span="24" style="text-align: center">
              操作
            </el-col>
            <el-col :span="24" style="margin-top: 20px;text-align: center">
              <el-button type="primary" size="mini" @click='uploadImg'>完成并上传</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 20px;text-align: center">
              <el-button type="primary" size="mini" @click='downloadImage'>下载</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 20px;text-align: center">
              <el-upload class="upload-demo" :action="uploadUrl" ref="exceptUpload" :headers="uploadHeader" :limit="2" :on-change="handleChange" :auto-upload="true">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">
                  上传后系统会重新识别 <br/>
                  只能上传png文件，且不超过2M
                </div>
              </el-upload>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <studentBox title="选择学生" :scanFileId="scanPre.id" :licenceId="scanPre.licence_id" ref="studentBox" @ok="selectStudent" :exceptType="scanPre.status" :updateStatus="'C1'"
                :examId="scanPre.exam_id" :searchText="null" :sameExamId="scanPre.same_exam_id" :top="200" :left="300" :right="0"></studentBox>

    <changeExamDialog ref="changeExamDialog" :scanPre="scanPre" @ok="selectChangeExam"></changeExamDialog>

  </div>

</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import XEUtils from 'xe-utils'
  import * as simpleApi from "request/simple";
  import * as mUtils from '@/utils/mUtils'
  import studentBox from "./studentBox";
  import changeExamDialog from "./changeExamDialog"
  import key from 'keymaster'
  import point from "./point";

  export default {
    name: 'posExceptPage',
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    props: {
      info: {
        type: Object
      }
    },
    components:{
      point, studentBox, changeExamDialog
    },
    watch: {
      info: {
        deep: true, immediate:true,
        handler(n, o){
          console.log(n)
          if(n){
            this.scanPre = n;
            this.loadExamPages();
          }
        }
      },
    },
    data() {
      return {
        scanPre: null, scanList: [], searchText: '', uploadUrl: null,
        uploadHeader: {
          'Company-url': 'http://www.szpcyl.com',
          'X-Powered-By': 'raozq',
          'ke-Token': 'noLogin',
          'ke-Domain': 'WB',
          // 'Content-Type':'multipart/form-data'
        },
        url: '', id: null, scanFileId: null, examId: null, myStyle: null,
        theDialogVisible: true, loading:false, tableData: [], pageLen: 1,
        getPre: null, imgInfo: null, pointList:[], cardInfo: null, selPoint: null,
        posWSize: "10", posHSize: "10", scaleDu: 0, myImg: null, imgWidth: 1000, imgHeight: 1000, imgScale: 1, canvasWidth: 1000, canvasHeight: 1000, canvasInfo: {w: 1000, h: 1000}, myCanvas: null,
        leftTop: [0.01684, 0.0404, 0, 0], rightTop: [0.9738, 0.0404, 0, 0], leftBottom: [0.01684, 0.94276, 0, 0], rightBottom: [0.9738, 0.94276, 0, 0],
        searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null},
      }
    },
    mounted() {
      let myHeight = document.documentElement.clientHeight ;
      this.myStyle = {height: (myHeight-200) + 'px'};
      key('s', ()=>{ this.keyS()});
      key('a', ()=>{ this.keyA()});
      key('w', ()=>{ this.keyW()});
      key('d', ()=>{ this.keyD()});
    },
    destroyed() {
      key.unbind("s","a","w","d", "shift+s","shift+a","shift+w","shift+d");
    },
    methods: {
      formatStatus(status){
        return mUtils.examStatus(status);
      },
      canDelete(){
        return this.userInfo.roles.some(item =>'superAdmin' == item);;
      },
      loadExamPages(){
        let that = this;
        console.log("loadExamPages")
        this.scaleDu = 0
        let search = { licenceId: this.scanPre.licence_id, id: this.scanPre.id};
        let param = { controllerName: 'scan/scanPre', methodName: '/getById',  param: search};
        return simpleApi.list(param).then(({result: {code, data}}) =>  {
          console.log("loadExamPages", data)
          if (data) {
            this.getPre = data;
            this.getPre.ossFullName = mUtils.urlAddTimestamp(this.getPre.ossFullName);
            this.loadCanvas()
          }
        }).catch((error) => {
          console.log("error", error)
        });
      },
      loadCanvas(){
        let that = this;
        this.$nextTick(() => {
          let myWidth = document.documentElement.clientWidth;
          let myHeight = document.documentElement.clientHeight;
          this.canvasWidth = myHeight - 150;
          this.canvasHeight = myHeight - 150;
          this.canvasInfo.w = this.canvasWidth
          this.canvasInfo.h = this.canvasHeight
          let canvas = document.getElementById("myCanvas1");

          if(canvas.getContext){
            that.myCanvas = canvas;
            that.ctx = canvas.getContext('2d');
            that.loadInit();

          }
        });
      },
      loadInit(){
        let that = this;
        that.myImg = new Image();
        that.myImg.src = that.getPre.ossFullName;
        that.myImg.onload = function () {
          that.imgWidth = this.width;
          that.imgHeight = this.height;
          // let w = that.canvasWidth, h = that.canvasHeight;
          // if(this.width > this.height){
          //   h = that.canvasHeight * (this.height/this.width);
          // }else{
          //   w = that.canvasWidth * (this.width/this.height);
          // }
          // that.ctx.drawImage(this, 0, 0, w, h);
          // console.log("loadCanvas ", that.imgWidth, that.imgHeight, w ,h , that.canvasWidth, that.canvasHeight)
          that.loadImage()
        }

      },
      loadImage(){
        let degree = this.scaleDu * (90 * Math.PI / 180);
        let w = this.canvasWidth, h = this.canvasHeight;
        if(this.imgWidth > this.imgHeight){
          h = this.canvasHeight * (this.imgHeight/this.imgWidth);
        }else{
          w = this.canvasWidth * (this.imgWidth/this.imgHeight);
        }
        console.log("loadImage ", degree, w ,h , this.canvasWidth, this.canvasHeight)
        this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
        switch (this.scaleDu){
          case 0:
            this.myCanvas.width = w
            this.myCanvas.height = h;
            this.ctx.drawImage(this.myImg, 0, 0, w, h);
            break;
          case 1:
            this.myCanvas.width = h
            this.myCanvas.height = w;
            this.ctx.rotate(degree)
            this.ctx.drawImage(this.myImg, 0, -h, w, h);
            break
          case 2:
            this.myCanvas.width = w
            this.myCanvas.height = h;
            this.ctx.rotate(degree)
            this.ctx.drawImage(this.myImg, 0, 0, -w, -h);
            break
          case 3:
            this.myCanvas.width = h
            this.myCanvas.height = w;
            this.ctx.rotate(degree)
            this.ctx.drawImage(this.myImg, -w, 0, w, h);
            break
        }
        this.anaInfo();
      },
      anaInfo(){
        let cardInfo = JSON.parse(this.getPre.cardInfo);
        if(cardInfo && cardInfo.length > 0){
          let width = this.canvasWidth
          let height = this.canvasHeight
          let posWSize = parseFloat(this.posWSize);
          let posHSize = parseFloat(this.posHSize);
          this.pointList = [];
          let canvas = document.getElementById("myCanvas1");
          console.log("anaInfo", canvas, width, height)

          let canvasDiv = document.getElementById("canvasDiv");
          let rect = canvas.getBoundingClientRect();
          let divRect = canvasDiv.getBoundingClientRect();
          let cX = 0, h = height;
          if(divRect.width != rect.width){ //如果是旋转，则需要加上X轴的偏移量
            cX = rect.x-divRect.x;
          }else{
            console.log("canvas !=:", divRect.height, rect.height)
            h = rect.height;
          }
          console.log("h:", h, this.leftBottom[1]*h, "canvas:", rect, "div:", divRect)
          let that = this;
          setTimeout(()=>{
            this.pointList = [];
            that.pointList.push({id: "leftTop", w: posWSize, h: posHSize, x: cX+that.leftTop[0]*rect.width, y:that.leftTop[1]*h
              , ws: that.leftTop[0], hs: that.leftTop[1], xb: that.leftTop[0], yb:that.leftTop[1], isR: false});
            that.pointList.push({id: "leftBottom", w: posWSize, h: posHSize, x: cX+that.leftBottom[0]*rect.width, y:that.leftBottom[1]*h
              , ws: that.leftBottom[0], hs: that.leftBottom[1], xb: that.leftBottom[0], yb:that.leftBottom[1], isR: false});
            that.pointList.push({id: "rightTop", w: posWSize, h: posHSize, x: cX+that.rightTop[0]*rect.width, y:that.rightTop[1]*h
              , ws: that.rightTop[0], hs: that.rightTop[1], xb: that.rightTop[0], yb:that.rightTop[1], isR: false});
            that.pointList.push({id: "rightBottom", w: posWSize, h: posHSize, x: cX+that.rightBottom[0]*rect.width, y:that.rightBottom[1]*h
              , ws: that.rightBottom[0], hs: that.rightBottom[1], xb: that.rightBottom[0], yb:that.rightBottom[1], isR: false});
            console.log("pointList", that.pointList);
          }, 500)
        }
        this.cardInfo = cardInfo;

      },
      xuanzhuan(v){
        if(v < 0){
          if(this.scaleDu == 0)  this.scaleDu = 4 + v; else this.scaleDu = this.scaleDu + v
        }else{
          this.scaleDu = this.scaleDu + v
        }

        if(Math.abs(this.scaleDu) ==4) this.scaleDu = 0;
        this.loadImage()
      },
      savePosition(){
        let canvas = document.getElementById("myCanvas1");
        let canvasDiv = document.getElementById("canvasDiv");
        let rect = canvas.getBoundingClientRect();
        let divRect = canvasDiv.getBoundingClientRect();
        let cX = 0, cY = rect.height-divRect.height, h = this.canvasHeight;
        if(divRect.width != rect.width){ //如果是旋转，则需要加上X轴的偏移量
          cX = rect.x-divRect.x;
        }else{
          console.log("canvas !=:", divRect.height, rect.height)
          h = rect.height;
        }
        let newPos = {};
        for (let i=0; i<this.pointList.length; i++){
          let p = this.pointList[i]
          let x = p.x - cX;
          p.xb = x/rect.width
          p.yb = p.y / rect.height
          console.log("pointList pos", x, p.y, p.xb, p.yb);
          if(p.xb > 1 || p.yb > 1 || p.xb < 0 || p.yb < 0){
            this.$alert(p.id + '超过图片范围！');
            return false
          }
          newPos[p.id] = [p.xb, p.yb]
        }
        this.cardInfo[0]["newLocation"] = { "rotate": this.scaleDu, "position": newPos}
        if(this.cardInfo.length > 1) this.cardInfo[1]["newLocation"] = { "rotate": this.scaleDu, "position": newPos}
        console.log("newLocation", this.cardInfo[0]["newLocation"])
        return true
      },
      saveOne(){
        if(this.savePosition()){
          let param = { controllerName: 'scan/scanPre', methodName: '/updateCardInfoByStatus',
            param: { id: this.scanPre.id, cardInfo: JSON.stringify(this.cardInfo), newStatus: "00"}};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              this.$message({ type: 'success', message: '重新定位变更成功!'});
              this.$emit('ok');
            }else{
              this.$message({ type: 'success', message: '重新定位变更失败!'});
            }
          });
        }
      },
      saveAll(){
        if(this.savePosition()){
          let param = { controllerName: 'scan/scanPre', methodName: '/updateCardInfoByStatus',
            param: { pageNo: this.getPre.pageNo, cardInfo: JSON.stringify(this.cardInfo), newStatus: "00",
              licenceId: this.scanPre.licence_id, examId: this.scanPre.exam_id, inStatus: ['Y5', 'Y6']}};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              this.$message({ type: 'success', message: '重新定位变更成功!'});
              this.$emit('ok');
            }else{
              this.$message({ type: 'success', message: '重新定位变更失败!'});
            }
          });
        }
      },
      keyS(){
        if(this.selPoint!=null){
          this.selPoint.y = this.selPoint.y + 1;
        }
      },
      keyW(){
        if(this.selPoint!=null){
          this.selPoint.y = this.selPoint.y -1;
        }
      },
      keyA(){
        if(this.selPoint!=null){
          this.selPoint.x = this.selPoint.x - 1;
        }
      },
      keyD(){
        if(this.selPoint!=null){
          this.selPoint.x = this.selPoint.x + 1;
        }
      },
      changePoint(pointId, newRect){
        let f = XEUtils.find(this.pointList, item => item.id == pointId);
        if(f){
          f.w = newRect.width;
          f.h = newRect.height;
          f.y = newRect.top;
          f.x = newRect.left;
          // console.log("changePoint", f, newRect);
        }

      },
      selectPoint(pointId){
        XEUtils.arrayEach(this.pointList, (item, key) => {
          item.isR=false;
        })
        let pointFind = XEUtils.find(this.pointList, item => item.id == pointId);
        if(pointFind){
          pointFind.isR=true;
          this.selPoint = pointFind;
        }
      },

      changePageNo(){
        let that = this;
        let status = this.getPre.status;
        this.$confirm('本操作应用于试卷倒反扫描，将改变页码顺序，是否确定操作?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {

          let param = { controllerName: 'scan/scanPre', methodName: '/changePageNo', param: { sameExamId: this.scanPre.same_exam_id, status: '01' }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '变更成功!'});
              this.$emit('ok', status);
            }
          });
        }).catch(() => {

        });
      },
      showChangeExamDialog(){
        this.$refs.changeExamDialog.showDialog();
      },
      changeStudentNo(){
        console.log("changeStudentNo", this.scanPre.student_no)
        this.$refs.studentBox.showDialog(this.scanPre.student_no);
      },
      uploadImg() {
        let that = this;
        this.loading = true;
        try{

          let img64 = this.$refs.tuiImageEditor1.invoke('toDataURL');
          // console.log("img64", img64)
          let param = { controllerName: 'scan/scanPre', methodName: '/base64Upload',
            param: { id: this.scanFileId, base64Data: img64}};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            console.log(code, data);
            if (code == 0) {
              that.$message({ type: 'success', message: '更改成功!'});
              that.$emit('ok');
              that.loading = false;
              return;
            }else{
              that.$message({ type: 'success', message: '更改失败!'});
            }
            that.loading = false;
          }).catch((e1) => {
            console.log("error", e1);
            that.loading = false;
          });
        }catch (e) {
          console.log(e);
          this.loading = false;
        }
      },
      downloadImage() {
        this.url = this.url.replace("http:", "https:");
        saveAs(this.url, "image.png");
      },
      handleChange(file, fileList) {
        console.log(file, fileList);
        if(file && "success" == file.status){
          this.$message({ type: 'success', message: '上传成功!'});
          this.$emit('ok');
        }
        let that = this;
        setTimeout(function () {
          that.$refs.exceptUpload.clearFiles();
        }, 1500);
      },
      selectStudent(sameExamId){
        this.$refs.studentBox.hideDialog();
        this.$message({ type: 'success', message: '更新成功!'});
        this.$emit('next', this.scanPre.status, sameExamId);
      },
      selectChangeExam(){
        this.$refs.changeExamDialog.hideDialog();
        this.$message({ type: 'success', message: '更新成功!'});
        this.$emit('changeExam');
      },
      doDeleteScanFile(){
        let that = this;
        let status = this.getPre.status;
        this.$confirm('将删除此试卷?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {

          let param = { controllerName: 'scan/scanPre', methodName: '/deleteBySameExamId', param: { sameExamId: this.scanPre.same_exam_id }};
          if(this.scanPre.same_exam_id == null){
            param = { controllerName: 'scan/scanPre', methodName: '/delete', param: { id: this.scanPre.id }};
          }
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '删除成功!'});
              this.$emit('ok', status);
            }
          });
        }).catch(() => {

        });
      },

    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

  .posExceptPage {

    .status-error{
      color: red;
    }
    .el-form-item__label {
      font-size: 16px;
      color: black;
    }
    .right-part {
      padding: 10px;
      border: 1px solid rgba(203, 202, 202, 0.71);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .data-row{
        margin-bottom: 10px;
      }
      .student-no-lost {
        text-align: left;
      }
      .base-info {
        text-align: left;
        margin-top: 10px;
        padding: 5px;
        border: 1px solid rgba(203, 202, 202, 0.71);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

  }




</style>
