<template>
    <el-container v-bind:id="sameExamId" class="studentBox" :style="myStyle" v-show="dialogVisible">
        <el-header style="height: 40px;">
            <div @mousedown="mousedown">
                <h3>{{title}}</h3>
                <div @click.stop="closeDialog()" class="header-btn" style="position: absolute;top: 0px; right: 20px;z-index:1000;">
                    <i class="el-icon-close" @click.stop="closeDialog()"></i>
                </div>
            </div>
        </el-header>
        <el-main>
            <el-row>
                <el-col :span="24">

                    <el-form :inline="true" :model='searchForm' ref="searchForm" @submit.native.prevent class="demo-form-inline search-form">
                        <el-form-item label="行政班"  prop="hardId" >
                            <el-select v-model="searchForm.hardId" filterable placeholder="请选择">
                                <el-option key="" label="全部" value=""></el-option>
                                <el-option v-for="item in hardClassList" :key="item.id" :label="item.name+'('+item.year+')'" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="查找" >
                            <el-input v-model="searchForm.searchText" placeholder="姓名或学号"  @keyup.enter.native="loadStudentList"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size ="mini" icon="search" @click='loadStudentList'>查询</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <el-row v-if="tableData.length > 0">
                <div class="table_container">
                    <el-table v-loading="loading" :data="tableData" :row-style="uploadRowStyle" style="width: 100%" align='center'>
                        <el-table-column prop="my_no" label="学号"  width="130" align='center' ></el-table-column>
                        <el-table-column prop="nick_name" label="姓名"  width="100" align='center' ></el-table-column>
                        <el-table-column prop="hard_name" label="班级"  width="100" align='center' ></el-table-column>
                        <el-table-column prop="kg_id" label="已关联"  :formatter="formatterKg" align='center' ></el-table-column>
                        <el-table-column prop="operation" align='center' label="操作" min-width="120">
                            <template slot-scope='scope'>
                                <el-button type="primary" size="mini" @click='selectStudent(scope.row)'>选择</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
                </div>
            </el-row>
        </el-main>
        <div @mousedown="mousedown" style="height: 40px;">
        </div>
    </el-container>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'
    import Pagination from "@/components/pagination";
    export default {
        name: 'studentBox',
        data(){
            return {
                dialogVisible: false, loading:false, tableData: [], hardClassList: [],
                myStyle: null, selectElement: '',
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, hardId: null, searchText: null},

            }
        },
        props: {
            title: String,
            scanFileId: String,
            updateStatus: {
                type: String,
                default: 'C1',
                require: false
            },
            exceptType: String,
            searchText: {
                type: String,
                default: '',
                require: false
            },
            examId: Number,
            sameExamId: String,
            top: Number,
            left: Number,
            right: Number,
        },
        components:{
            Pagination
        },
        watch: {
            searchText: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.searchForm.searchText = n
                    }
                }
            },
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
            this.selectElement = document.getElementById(this.sameExamId)
            var div1 = this.selectElement;
            if(this.top > 0) div1.style.top = this.top + 'px';
            if(this.left > 0) div1.style.left = this.left + 'px';
            if(this.right > 0) div1.style.right = this.right + 'px'
            this.loadHardClassIdList();
	    },

        methods: {
            searchStudent(v){
                if(v && v.length > 3){
                    this.loadStudentList();
                    return;
                }
                if(!v || v.length < 1){
                    this.tableData = [];
                    return;
                }
                console.log("searchStudent", v);
            },
            formatterKg(row, column, cellValue, index) {
                if(cellValue && cellValue > 0){
                    return '是';
                }
                return "否";

            },
            uploadRowStyle({row, rowIndex}){
                if(row.kg_id && row.kg_id != null){
                    return {color: 'red' };
                }
            },
            loadHardClassIdList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'sclHardClass', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.hardClassList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            mousedown(event) {
                this.selectElement = document.getElementById(this.sameExamId)
                var div1 = this.selectElement
                this.selectElement.style.cursor = 'move'
                this.isDowm = true
                var distanceX = event.clientX - this.selectElement.offsetLeft
                var distanceY = event.clientY - this.selectElement.offsetTop
                document.onmousemove = function (ev) {
                    var oevent = ev || event
                    div1.style.left = oevent.clientX - distanceX + 'px'
                    div1.style.top = oevent.clientY - distanceY + 'px'
                }
                document.onmouseup = function () {
                    document.onmousemove = null
                    document.onmouseup = null
                    div1.style.cursor = 'default'
                }
            },
            closeDialog(e) {
                console.log('close dialog....')
                this.dialogVisible = false
            },
            loadStudentList(){
                this.loading = true;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    pageNo: 1, examId: this.examId, hardId: mUtils.searchText(this.searchForm.hardId), orderBy: 's.my_no'};
                let param = { controllerName: 'scan/scanPre', methodName: '/studentList', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },

            selectStudent(row){

                let that = this;
                    let status = this.updateStatus;
                    let param = { controllerName: 'scan/scanPre', methodName: '/assignStudent',
                        param: { id: this.scanFileId, sameExamId: this.sameExamId, studentId: row.id, studentNo: row.my_no, studentName: row.nick_name, status: status }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '关联成功!'});
                            that.tableData = [];
                            that.searchForm.searchText = null;
                            that.$emit('ok', this.sameExamId);
                        }
                    });
                // }else{
                //     let param = { controllerName: 'exam/noLose', methodName: '/update',
                //         param: { id: this.id, scanFileId: this.scanFileId, studentId: row.id, studentNo: row.my_no, studentName: row.nick_name, status: 'C4' }};
                //     simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                //         if (code == 0) {
                //             that.$message({ type: 'success', message: '关联成功!'});
                //             that.tableData = [];
                //             that.searchForm.searchText = null;
                //             that.$emit('ok');
                //         }
                //     });


            },
            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadStudentList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadStudentList()
            },
            showDialog(searchText){
                console.log("showDialog", searchText)
                this.tableData = [];
                this.searchForm.hardId = null;
                if(searchText!=null && searchText != ''){
                    this.searchForm.searchText = searchText;
                    this.loadStudentList();
                }
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },

            submitForm(){

            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .studentBox{
        position: absolute;
        z-index:999;
        min-height: 300px;
        min-width: 500px;
        max-height: 650px;
        width: 40%;
        border: 1px;
        top: 20%;
        right: 1%;
        border-radius: 2px;
        border:1px solid gray;
        overflow: hidden;

        div::-webkit-scrollbar {
            display: none;
        }

        .toolImg {
            background: no-repeat center left;
            width:63px;
            height:38px;
            display: inline-block;
            &:hover {
                background:#eeeeee;
            }
        }
        .el-header {
            background-color: white;
            color: #333;
            line-height: 40px;
            &:hover {
                cursor:move;
            }
            div h3 {
                -webkit-margin-before: 0px;
                -webkit-margin-after: 0px;
                &:hover {
                    cursor:move;
                }
            }
            .header-btn {
                &:hover {
                    cursor:pointer;
                }
            }
        }

        .el-main {
            background-color: white;
            padding: 5px;
            overflow-x: hidden;
            overflow-y: auto;
            .el-row {
                margin-bottom: 10px;

                .search-form{
                    min-width: 400px;
                }
                .circle {
                    width: 20px;  height: 20px;  background-color:#8c939d; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%;
                }

            }
        }

        .el-aside {
            color: #333;
        }
    }


</style>


