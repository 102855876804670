<template>
  <div class="waitPage" >
<!--    <el-row>-->
<!--      <el-col :span="24">-->
<!--        <span style="color: #F6F7FC;">{{scanPre.id}}, {{scanPre.seq}}, {{scanPre.same_exam_id}}</span>-->
<!--        <span >批次号：{{scanPre.batch_id}}, 序号：{{scanPre.seq}}</span>-->

<!--      </el-col>-->
<!--    </el-row>-->
    <el-row v-if="getPre != null">
      <el-col :span="20">
        <div style="width: 1000px; height: auto">
          <img style="width: 1000px; height:auto" ref="waitImg" :src="getPre.cutOssFullName" @load="loadImage"/>
          <point :w="item.w" :h="item.h" :x="item.x" :y="item.y" :id="item.id" v-for="item in pointList"
                 :drawData="item.drawData" :isResizable="item.isR" @select="selectPoint" @repos="changePoint"></point>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="right-part" :style="myStyle">
          <el-row class="base-info" >
            <el-col :span="24">
              基本信息
            </el-col>
            <el-col :span="24" style="white-space:nowrap;">
              id：{{scanPre.id}}
            </el-col>
            <el-col :span="24" style="white-space:nowrap;">
              same id：{{scanPre.same_exam_id}}
            </el-col>
            <el-col :span="24" style="white-space:nowrap;">
              批次号：{{scanPre.batch_id}}
            </el-col>
            <el-col :span="24">
              状态：<span :class="scanPre.status=='ZC'?'': 'status-error'">{{formatStatus(scanPre.status)}}</span>
            </el-col>
          </el-row>

          <el-row style="margin-top: 20px; text-align: center" class="base-info">
            <el-col :span="24">
              操作
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='savePoint'>保存定位</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='saveShibie()'>识别单张</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click='savePointAndRun'>保存定位并全部识别</el-button>
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="warning" size="mini" @click='saveExcept'>转为定位异常</el-button>
            </el-col>
          </el-row>

          <el-row style="margin-top: 20px; text-align: center" class="base-info">
            <el-col :span="24" style="color: red; ">
              危险操作
            </el-col>
            <el-col :span="24" style="margin-top: 15px;">
              <el-button type="danger" size="mini" @click='doDeleteScanFile()'>删除</el-button>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>


<!--    <studentBox v-if="scanPre != null" title="选择学生" :scanFileId="scanPre.id" ref="studentBox" @ok="selectStudent" :exceptType="scanPre.status"-->
<!--                :examId="scanPre.exam_id" :searchText="searchText" :sameExamId="scanPre.same_exam_id" :top="200" :left="300" :right="0"></studentBox>-->


  </div>

</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import XEUtils from 'xe-utils'
  import * as simpleApi from "request/simple";
  import * as mUtils from '@/utils/mUtils'
  import key from 'keymaster'
  import point from "./point";

  export default {
    name: 'waitPage',
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    props: {
      info: {
        type: Object
      }
    },
    components:{
      point
    },
    watch: {
      info: {
        deep: true, immediate:true,
        handler(n, o){
          console.log(n)
          if(n){
            this.scanPre = n;
            this.loadExamPages();
          }
        }
      },
    },
    data() {
      return {
        scanPre: null, scanList: [], searchText: '',
        url: '', id: null, scanFileId: null, examId: null, myStyle: null,
        theDialogVisible: true, loading:false, tableData: [], pageLen: 1,
        getPre: null, imgInfo: null, pointList:[], cardInfo: null, selPoint: null,
        searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null},
      }
    },
    mounted() {
      let myHeight = document.documentElement.clientHeight ;
      this.myStyle = {height: (myHeight-200) + 'px'};
      key('shift+s', ()=>{ this.keyShiftS()});
      key('shift+a', ()=>{ this.keyShiftA()});
      key('shift+w', ()=>{ this.keyShiftW()});
      key('shift+d', ()=>{ this.keyShiftD()});
      key('s', ()=>{ this.keyS()});
      key('a', ()=>{ this.keyA()});
      key('w', ()=>{ this.keyW()});
      key('d', ()=>{ this.keyD()});
    },
    destroyed() {
      key.unbind("s","a","w","d", "shift+s","shift+a","shift+w","shift+d");
    },
    methods: {
      formatStatus(status){
        return mUtils.examStatus(status);
      },
      loadExamPages(){
        let that = this;
        console.log("loadExamPages")
        let search = { licenceId: this.userInfo.licenceId, id: this.scanPre.id};
        let param = { controllerName: 'scan/scanPre', methodName: '/getById',  param: search};
        return simpleApi.list(param).then(({result: {code, data}}) =>  {
          console.log("loadExamPages", data)
          if (data) {
            this.getPre = data;
            this.getPre.cutOssFullName = mUtils.urlAddTimestamp(this.getPre.cutOssFullName);

          }
        }).catch((error) => {
          console.log("error")
        });
      },
      loadImage(){
        this.cardInfoAndImage(this.getPre)
      },
      cardInfoAndImage(data){
        let that = this;
        try{
          that.pointList = [];
          if(data.cardInfo){
            let cardInfo = JSON.parse(data.cardInfo);
            this.pageLen = cardInfo.length;
            that.$nextTick(() => {
              let wm = this.$refs["waitImg"];
              let imgInfo = wm.getBoundingClientRect()
              if(imgInfo && "height" in imgInfo && imgInfo.height > 0){
                that.imgInfo = imgInfo;
                that.anaInfo(cardInfo);
              // }else{
              //   setTimeout(that.anaInfo(cardInfo), 500);
              }
            });
          }
        }catch (e) {
          console.log("error", e)
        }
      },
      anaInfo(cardInfo){
        let index = 0;
        if(!this.imgInfo || !"height" in this.imgInfo || this.imgInfo.height ==0){
          this.imgInfo = this.$refs["waitImg"].getBoundingClientRect();
        }
        console.log("card", cardInfo, this.imgInfo);
        let {height, left, right, top, width,} = this.imgInfo;
        if(cardInfo && cardInfo.length > 0){
          if("noAnswerMark" in cardInfo[this.getPre.pageNo-1]){
            let pos = cardInfo[this.getPre.pageNo-1].noAnswerMark.position;
            // console.log("noAnswerMark", pos)
            pos["id"] = "id-"+index;
            let p = {id: "id-"+index, w: pos.width_scale*width, h: pos.height_scale*height, x: pos.left_scale*width, y:pos.top_scale*height
              , ws: pos.width_scale, hs: pos.height_scale, xb: pos.left_begin, yb:pos.top_begin, isR: false};
            // console.log("noAnswerMark", p.id, p.x, p.y, p.w, p.h);
            this.pointList.push(p);
            index = index + 1;
          }
          if("paperAB" in cardInfo[this.getPre.pageNo-1]){
            let paperAB = cardInfo[this.getPre.pageNo-1].paperAB;
            for (let ii=0; ii<paperAB.length; ii++){
              let pos = paperAB[ii].position;
              console.log("paperAB", ii, pos)
              pos["id"] = "id-"+index;
              let p = {id: "id-"+index, w: pos.width_scale*width, h: pos.height_scale*height, x: pos.left_begin*width, y:pos.top_begin*height
                , ws: pos.width_scale, hs: pos.height_scale, xb: pos.left_begin, yb:pos.top_begin, isR: false};
              // console.log("noAnswerMark", p.id, p.x, p.y, p.w, p.h);
              this.pointList.push(p);
              index = index + 1;
            }

          }
          for (let i=0; i<cardInfo[this.getPre.pageNo-1].sections.length; i++){
            let sect = cardInfo[this.getPre.pageNo-1].sections[i];
            for (let j=0; j<sect.list.length; j++){
                let t = sect.list[j];
              let pos = t.position;
              pos["id"] = "id-"+index;
              let p = {id: "id-"+index, w: pos.width_scale*width, h: pos.height_scale*height, x: pos.left_begin*width, y:pos.top_begin*height
                , ws: pos.width_scale, hs: pos.height_scale, xb: pos.left_begin, yb:pos.top_begin, isR: false};
              if(("regNo" in t)) p["drawData"] = { type: 'regNo', list: t.regNo};
              if(t.type=='studentNo'){
                p["drawData"] = { type: 'regNo', position: pos, list: null, count: t.count};
              }
            if(t.type == "Object") p["drawData"] = { type: 'Object', position: pos, list: t.branch};

              // console.log(p.id, p.x, p.y, p.w, p.h);
              this.pointList.push(p);
              index = index + 1;
            }
          }
        }
        this.cardInfo = cardInfo;
        console.log("pointList", this.cardInfo, this.pointList);
      },
      keyShiftS(){
        if(this.selPoint!=null){
          this.selPoint.h = this.selPoint.h + 1;
        }
      },
      keyShiftW(){
        if(this.selPoint!=null){
          this.selPoint.h = this.selPoint.h - 1;
        }
      },
      keyShiftA(){
        if(this.selPoint!=null){
          this.selPoint.w = this.selPoint.w - 1;
        }
      },
      keyShiftD(){
        if(this.selPoint!=null){
          this.selPoint.w = this.selPoint.w + 1;
        }
      },
      keyS(){
        if(this.selPoint!=null){
          this.selPoint.y = this.selPoint.y + 1;
        }
      },
      keyW(){
        if(this.selPoint!=null){
          this.selPoint.y = this.selPoint.y -1;
        }
      },
      keyA(){
        if(this.selPoint!=null){
          this.selPoint.x = this.selPoint.x - 1;
        }
      },
      keyD(){
        if(this.selPoint!=null){
          this.selPoint.x = this.selPoint.x + 1;
        }
      },
      changePoint(pointId, newRect){
        let f = XEUtils.find(this.pointList, item => item.id == pointId);
        if(f){
          f.w = newRect.width;
          f.h = newRect.height;
          f.y = newRect.top;
          f.x = newRect.left;
          // console.log("changePoint", f, newRect);
        }

      },
      selectPoint(pointId){
        XEUtils.arrayEach(this.pointList, (item, key) => {
          item.isR=false;
        })
        let pointFind = XEUtils.find(this.pointList, item => item.id == pointId);
        if(pointFind){
          pointFind.isR=true;
          this.selPoint = pointFind;
        }
      },
      savePoint(callback){
        console.log("savePoint", this.pointList);
        let pageNo = this.getPre.pageNo-1;
        let {height, left, right, top, width,} = this.imgInfo;
        for (let i=0 ;i<this.pointList.length; i++){
          let p = this.pointList[i];
          let myPage = this.cardInfo[pageNo];
          if("paperAB" in myPage){
            let paperAB = myPage.paperAB;
            // console.log("paperAB", paperAB);
            for(let j=0; j<paperAB.length;j++){
              let ab = paperAB[j].position;
              if(ab.id == p.id){
                ab["width_scale"] = p.w / width;
                ab["height_scale"] = p.h / height;
                ab["left_begin"] = p.x / width;
                ab["left_end"] = (p.x+p.w) / width;
                ab["top_begin"] = (p.y) / height;
                ab["top_end"] = (p.y+p.h) / height;
                console.log("ab", ab, p, left, top);
                break;
              }
            }
          }
          for (let j=0; j<myPage.sections.length; j++){
            let sect = myPage.sections[j];
            for (let k=0; k<sect.list.length; k++){
              let pos = sect.list[k].position;
              if(pos["id"] == p.id){
                pos["width_scale"] = p.w / width;
                pos["height_scale"] = p.h / height;
                pos["left_begin"] = p.x / width;
                pos["left_end"] = (p.x+p.w) / width;
                pos["top_begin"] = (p.y) / height;
                pos["top_end"] = (p.y+p.h) / height;
                console.log("pos", pos, p, left, top);
                break;
              }
            }
          }
        }
        let param = { controllerName: 'scan/scanPre', methodName: '/updateCardInfo',
          param: { id: this.scanPre.id, cardInfo: JSON.stringify(this.cardInfo) }};
        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
          if (code == 0) {
            this.$message({ type: 'success', message: '变更位置成功!'});
            if(callback) callback();
          }else{
            this.$message({ type: 'success', message: '变更位置失败!'});
          }
        });
      },
      savePointAndRun(){
        console.log("savePointAndRun", this.pointList);

        this.$confirm('此操作会将改试卷所有的符合的记录都更改，是否确定?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {

          let pageNo = this.getPre.pageNo-1;
          let {height, left, right, top, width,} = this.imgInfo;
          for (let i=0 ;i<this.pointList.length; i++){
            let p = this.pointList[i];
            let myPage = this.cardInfo[pageNo];
            if("paperAB" in myPage){
              let paperAB = myPage.paperAB;
              // console.log("paperAB", paperAB);
              for(let j=0; j<paperAB.length;j++){
                let ab = paperAB[j].position;
                if(ab.id == p.id){
                  ab["width_scale"] = p.w / width;
                  ab["height_scale"] = p.h / height;
                  ab["left_begin"] = p.x / width;
                  ab["left_end"] = (p.x+p.w) / width;
                  ab["top_begin"] = (p.y) / height;
                  ab["top_end"] = (p.y+p.h) / height;
                  console.log("ab", ab, p, left, top);
                  break;
                }
              }
            }
            for (let j=0; j<myPage.sections.length; j++){
              let sect = myPage.sections[j];
              for (let k=0; k<sect.list.length; k++){
                let pos = sect.list[k].position;
                if(pos["id"] == p.id){
                  pos["width_scale"] = p.w / width;
                  pos["height_scale"] = p.h / height;
                  pos["left_begin"] = p.x / width;
                  pos["left_end"] = (p.x+p.w) / width;
                  pos["top_begin"] = (p.y) / height;
                  pos["top_end"] = (p.y+p.h) / height;
                  console.log("pos", pos, p, left, top);
                  break;
                }
              }
            }
          }
          let param = { controllerName: 'scan/scanPre', methodName: '/updateCardInfoByStatus',
            param: { status: '01', cardInfo: JSON.stringify(this.cardInfo), newStatus: 'SB', licenceId: this.userInfo.licenceId,
              examId: this.scanPre.exam_id, pageNo: this.getPre.pageNo }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              this.$message({ type: 'success', message: '更新成功!'});
              this.$emit('ok');
            }else{
              this.$message({ type: 'success', message: '更新失败!'});
            }
          });
        }).catch(() => {

        });

      },
      saveShibie(){
        let that = this;
        this.savePoint(function () {

          let param = { controllerName: 'scan/scanPre', methodName: '/updateById',
            param: { id: that.scanPre.id, status: "SB" }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '设置成功!'});
              that.$emit('ok');
            }else{
              that.$message({ type: 'success', message: '设置失败!'});
            }
          });
        });
      },
      saveExcept(){
        let that = this;
        this.savePoint(function () {

          let param = { controllerName: 'scan/scanPre', methodName: '/updateById',
            param: { id: that.scanPre.id, status: "Y5" }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '设置成功!'});
              that.$emit('ok');
            }else{
              that.$message({ type: 'success', message: '设置失败!'});
            }
          });
        });
      },
      changePageNo(){
        let that = this;
        let status = this.getPre.status;
        this.$confirm('本操作应用于试卷倒反扫描，将改变页码顺序，是否确定操作?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {

          let param = { controllerName: 'scan/scanPre', methodName: '/changePageNo', param: { sameExamId: this.scanPre.same_exam_id, status: '01' }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '变更成功!'});
              this.$emit('ok', status);
            }
          });
        }).catch(() => {

        });
      },
      doDeleteScanFile(){
        let that = this;
        let status = this.getPre.status;
        this.$confirm('将删除此试卷?', '提示', {
          confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {

          let param = { controllerName: 'scan/scanPre', methodName: '/deleteBySameExamId', param: { sameExamId: this.scanPre.same_exam_id }};
          simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
            if (code == 0) {
              that.$message({ type: 'success', message: '删除成功!'});
              this.$emit('ok', status);
            }
          });
        }).catch(() => {

        });
      },

    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

  .waitPage {

    .status-error{
      color: red;
    }
    .el-form-item__label {
      font-size: 16px;
      color: black;
    }
    .right-part {
      padding: 10px;
      border: 1px solid rgba(203, 202, 202, 0.71);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .data-row{
        margin-bottom: 10px;
      }
      .student-no-lost {
        text-align: left;
      }
      .base-info {
        text-align: left;
        margin-top: 10px;
        padding: 5px;
        border: 1px solid rgba(203, 202, 202, 0.71);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

  }




</style>
